import React, { useEffect, useState } from 'react'
import Footer from '../superAdmin/footer/Footer'
import Select from "react-select";
import DatePickers from '../../common/DatePickers';
import { message, notification } from "antd";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Table from "react-bootstrap/Table";
import CommonFileUploader from '../../common/CommonFileUploader';
import Tandcform from './Tandcform';

// Function to convert a date string to ISO 8601 format
const convertToISO = (dateStr) => {
  // Parse the date string to a Date object
  const date = new Date(dateStr);
  // Convert to ISO 8601 format with time set to 00:00:00
  return date.toISOString().split('T')[0] + 'T00:00:00';
};

// Function to check if a given date is in the specified range
const isDateInRange = (date) => {
  // Define the start and end dates in ISO format
  const startDate = new Date(getNextDayFormatted(process.env.REACT_APP_START_DATE));
  const endDate = new Date(process.env.REACT_APP_END_DATE);

  // Convert the given date to ISO format and create a Date object
  const givenDate = new Date(convertToISO(date));

  // Perform the date range check
  return givenDate >= startDate && givenDate <= endDate;
};



// const isDateInRange = (date) => {
//   // Define the start and end dates in ISO format
//   const startDate = new Date(getNextDayFormatted(process.env.REACT_APP_START_DATE_FLIGHT));
//   const endDate = new Date(process.env.REACT_APP_END_DATE_FLIGHT);

//   // Convert the given date to ISO format and create a Date object
//   const givenDate = new Date(convertToISO(date));

//   // Perform the date range check
//   return givenDate >= startDate && givenDate <= endDate;
// };

function getNextDayFormatted(date) {
  return moment(date).add(1, 'days').format('lll');
}
// Example usage
const inputDate = "Nov 14, 2024";
const isValid = isDateInRange(inputDate);

console.log(isValid); // Output: false (since Nov 14, 2024 is not in the range)


const BuyerRegistrationForm = ({ phoneNumber }) => {
  const company_category_array = [
    "Retailer",
    "Distributor",
    "Wholesaler"
  ]
  const designationArray = [
    "Director",
    "Partner",
    "Staff"];
  const [representative_list, setRepresentative_list] = useState([
    "Akhil",
    "Kapil Ladiwal",
    "Nisha",
    "Arpan",
    "Arpan",
    "Venkat",
    "Aarpan",
    "Abhishek Jain",
    "Sanjay",
    "Mr.Venkatasayee",
    "Venkatasayee. B",
    "Tanmay Das",
    "Sonu Vats",
    "T Das",
    "Pradnya H",
    "Kapil Ji",
    "Shrey Kamdar",
    "Bhavin Z",
    "Mr. T. Das",
    "Venkatesh Iyer",
    "Kapil",
    "Karan",
    "Akil Aravind",
    "Venkatasayee Balasunderam",
    "Sonu",
    "Venkatasayee",
    "Nitin",
    "Arjun Mistry",
    "Mr.Venkat",
    "Umesh",
    "Meenakshi Jain",
    "Akhil Aravind",
    "Akhl"
  ])
  const initialFormData = {
    representative: '',
    companyName: '',
    roomNo: '',
    title: '',
    personName: '',
    mobileNumber: phoneNumber || localStorage.getItem('mobile_number'),
    emailAddress: '',
    designation: '',
    country: '',
    alloted_car: '',
    roomOccupancy: '',
    ticketsDepartureBookedBy: '',
    ticketsReturnBookedBy: '',
    ticketsDepartureBookedBy2nd: '',
    ticketsReturnBookedBy2nd: '',
    travelMode: '',
    travelMode2nd: '',
    travelReturnMode: '',
    travelReturnMode2nd: '',
    departurePNR: '',
    otherDesignation: '',
    departureCity: '',
    departureDate: '',
    flightTrainName: '',
    departure_time_from_source: '',
    flightTrainNumber: '',
    arrivalTimeGoa: '',
    goaAirport: '',
    returnPNR: '',
    returnCity: '',
    returnFlightDate: '',
    returnFlightName: '',
    returnFlightNumber: '',
    returnFlightDepartureTime: '',
    returnAirport: '',
    title_2nd: '',
    personName2nd: '',
    mobileNumber2nd: '',
    emailAddress2nd: '',
    designation2nd: '',
    country2nd: '',
    alloted_car2nd: '',
    roomOccupancy2nd: '',
    departurePNR2nd: '',
    otherDesignation2nd: '',
    departureCity2nd: '',
    departureDate2nd: '',
    flightTrainName2nd: '',
    flightTrainNumber2nd: '',
    arrivalTimeGoa2nd: '',
    goaAirport2nd: '',
    returnPNR2nd: '',
    returnCity2nd: '',
    returnFlightDate2nd: '',
    departure_time_from_source2nd: '',

    returnFlightName2nd: '',
    returnFlightNumber2nd: '',
    returnFlightDepartureTime2nd: '',
    returnAirport2nd: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [hotelCheckInDate, setHotelCheckInDate] = useState(null)
  const [hotelCheckOutDate, setHotelCheckOutDate] = useState(null)
  const [departureDate, setDepartureDate] = useState(null)
  const [returnFlightDate, setReturnFlightDate] = useState(null)
  const [hotelCheckInDate2nd, setHotelCheckInDate2nd] = useState(null)
  const [hotelCheckOutDate2nd, setHotelCheckOutDate2nd] = useState(null)
  const [departureDate2nd, setDepartureDate2nd] = useState(null)
  const [returnFlightDate2nd, setReturnFlightDate2nd] = useState(null)
  const urlPath = window.location.pathname; // This will return '/buyer-registration-form'
  const extractedPart = urlPath.split('/').pop(); // This will return 'buyer-registration-form'
  const [travelModerarrivalArray2nd, setTravelModerarrivalArray2nd] = useState(["Train", "Flight", "Self"])
  const [travelModerarrivalArray, setTravelModerarrivalArray] = useState(["Train", "Flight", "Self"])
  const [travelModerDepartureArray, setTravelModerDepartureArray] = useState(["Train", "Flight", "Self"])
  const [travelModerDepartureArray2nd, setTravelModerDepartureArray2nd] = useState(["Train", "Flight", "Self"])
  const [isChecked1st, setIsChecked1st] = useState(false);
  const [isChecked2nd, setIsChecked2nd] = useState(true);
  const [isChecked3rd, setIsChecked3rd] = useState(true);
  const [showBlock2nd, setShowBlock2nd] = useState(false)
  const [showBlock3rd, setShowBlock3rd] = useState(false)
  const [showBlock4th, setShowBlock4th] = useState(false)
  const [showBlock5th, setShowBlock5th] = useState(false)
  const [showBlock6th, setShowBlock6th] = useState(false)
  const [gst_number, setGst_number] = useState(null)
  const [company_city, setCompany_city] = useState(null)
  const [company_state, setCompany_state] = useState(null)
  const [company_address, setCompany_address] = useState(null)
  const [company_pincode, setCompany_pincode] = useState(null)
  const navigate = useNavigate()
  const [gst_data_present, setGst_data_present] = useState(null)
  const [fristMobileNumberError, setFristMobileNumberError] = useState(null)
  const [secondMobileNumberError, setSecondMobileNumberError] = useState(null)
  const [no_of_stores, set_no_of_stores] = useState(null)
  const [sqft_area, set_sqft_area] = useState(null)
  const [no_of_staff, set_no_of_staff] = useState(null)
  const [company_category, set_company_category] = useState(null)
  const [photo, setPhoto] = useState();
  const [idProofFront, setIDProofFront] = useState();
  const [idProofBack, setIDProofBack] = useState();
  const [second_photo, set_second_photo] = useState();
  const [second_idProofFront, set_second_idProofFront] = useState();
  const [second_idProofBack, set_second_idProofBack] = useState();
  const [duplicateError, setDuplicateError] = useState(null);
  const [error_1st, set_error_1st] = useState(false);
  const [error_2nd, set_error_2nd] = useState(null);
  const [error_3rd, set_error_3rd] = useState(null);
  const [error_4th, set_error_4th] = useState(null);
  const [error_5th, set_error_5th] = useState(null);
  const [error_6th, set_error_6th] = useState(null);
  const [error_7th, set_error_7th] = useState(null);
  const [error_8th, set_error_8th] = useState(null);
  const [error_9th, set_error_9th] = useState(null);
  const [error_10th, set_error_10th] = useState(null);
  const [error_11th, set_error_11th] = useState(null);
  const [loader, setLoader] = useState(false)
  const [isChecked, setIsChecked] = useState(true)
  const [categories, setCategories] = useState([
    { id: 1, name: 'Gold', percentage: '' },
    { id: 2, name: 'Diamond', percentage: '' },
    { id: 3, name: 'Others', percentage: '' },
  ]);
  const [error, setError] = useState('');

  const [showError, setShowError] = useState("");

  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  function checkRegex(input) {
    return regex.test(input);
  }

  function convertToUpperCase(input) {
    return input.replace(/[a-z]/gi, (char) => char.toUpperCase());
  }

  const handleGstNumberChange = (e) => {
    setGst_number(convertToUpperCase(e.target.value));
    const value = e.target.value;
    if (checkRegex(value)) {
      console.log("asdasdasd", e.target.value);
      setShowError("");
    } else {
      setShowError("Provide Valid GSTIN");
    }
  };

  // console.log(extractedPart);
  const handleSelectChange = (selectedOption, object) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    if (object === "2nd") {
      if (selectedValue !== "others") {
        setFormData({ ...formData, designation2nd: selectedValue, otherDesignation2nd: "" });
      } else {
        setFormData({ ...formData, designation2nd: "others" });
      }
    } else {

      if (selectedValue !== "others") {
        setFormData({ ...formData, designation: selectedValue, otherDesignation: "" });
      } else {
        setFormData({ ...formData, designation: "others" });
      }
    }
  };

  const handleOtherDesignationChange = (event, object) => {
    const value = event.target.value;
    if (object === "2nd") {
      setFormData({ ...formData, otherDesignation2nd: value });

    } else {

      setFormData({ ...formData, otherDesignation: value });
    }
  };
  // Create a mapping object for field names to display names
  const fieldDisplayNames = {
    representative: 'Representative',
    companyName: 'Company Name',
    roomNo: 'Room Number',
    roomOccupancy: 'Room Occupancy',
    personName: 'Person Name',
    emailAddress: 'Email Address',
    mobileNumber: 'Mobile Number',
    designation: 'Designation',
    hotelCheckInDate: 'Hotel Check-In Date',
    hotelCheckOutDate: 'Hotel Check-Out Date',
    ticketsBookedBy: 'Tickets Booked By',
    travelMode: 'Travel Mode',
    travelReturnMode: 'Return Travel Mode',

    departurePNR: 'Departure PNR',
    departureCity: 'Departure City',
    departureDate: 'Departure Date',
    flightTrainName: 'Flight/Train Name',
    flightTrainNumber: 'Flight/Train Number',
    arrivalTimeGoa: 'Arrival Time in Goa',
    goaAirport: 'Goa Airport',
    returnPNR: 'Return PNR',
    returnCity: 'Return City',
    returnFlightDate: 'Return Flight Date',
    returnFlightName: 'Return Flight Name',
    returnFlightNumber: 'Return Flight Number',
    returnFlightDepartureTime: 'Return Flight Departure Time',
    returnAirport: 'Return Airport of 2nd person',
    personName2nd: 'Person Name of 2nd person',
    emailAddress2nd: 'Email Address of 2nd person',
    mobileNumber2nd: 'Mobile Number of 2nd person',
    designation2nd: 'Designation of 2nd person',
    hotelCheckInDate2nd: 'Hotel Check-In Date of 2nd person',
    hotelCheckOutDate2nd: 'Hotel Check-Out Date of 2nd person',
    ticketsBookedBy2nd: 'Tickets Booked By of 2nd person',
    travelMode2nd: 'Travel Mode of 2nd person',
    travelReturnMode2nd: 'Return Travel Mode 2nd person',

    departurePNR2nd: 'Departure PNR of 2nd person',
    departureCity2nd: 'Departure City of 2nd person',
    departureDate2nd: 'Departure Date of 2nd person',
    flightTrainName2nd: 'Flight/Train Name of 2nd person',
    flightTrainNumber2nd: 'Flight/Train Number of 2nd person',
    arrivalTimeGoa2nd: 'Arrival Time in Goa of 2nd person',
    goaAirport2nd: 'Goa Airport of 2nd person',
    returnPNR2nd: 'Return PNR of 2nd person',
    returnCity2nd: 'Return City of 2nd person',
    returnFlightDate2nd: 'Return Flight Date of 2nd person',
    returnFlightName2nd: 'Return Flight Name of 2nd person',
    returnFlightNumber2nd: 'Return Flight Number of 2nd person',
    returnFlightDepartureTime2nd: 'Return Flight Departure Time of 2nd person',
    returnAirport2nd: 'Return Airport of 2nd person'

  };

  const ticket_by = ["IGI", "Self"]
  const occupancy_list = ["Single", "Double"]
  const airportListOfGoa = ["Dabolim Airport and Manohar International Airport", "Mopa Airport"]
  const titleData = ["Mr.", "Ms."]
  // Array of required fields
  const requiredFields = [
    'representative',
    'companyName',
    'personName',
    'mobileNumber',
    'designation',
    'travelMode',
    'roomOccupancy',
    // 'departurePNR',
    // 'departureCity',
    // 'flightTrainName',
    // 'flightTrainNumber',
    // 'arrivalTimeGoa',
    // 'goaAirport',
    // 'returnPNR',
    // 'returnCity',
    // 'returnFlightName',
    // 'returnFlightNumber',
    // 'returnFlightDepartureTime',
    // 'returnAirport',


  ];

  const requiredforSecondFields = [
    'personName2nd',
    'mobileNumber2nd',
    'designation2nd',
    // 'departurePNR2nd',
    // 'departureCity2nd',
    // 'flightTrainName2nd',
    // 'flightTrainNumber2nd',
    // 'arrivalTimeGoa2nd',
    // 'goaAirport2nd',
    // 'returnPNR2nd',
    // 'returnCity2nd',
    // 'returnFlightName2nd',
    // 'returnFlightNumber2nd',
    // 'returnFlightDepartureTime2nd',
    // 'returnAirport2nd',


  ];

  // Validation function to check if all required fields are filled
  const validateForm = () => {
    let isValid = true;
    let missingFields = [];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        isValid = false;
        missingFields.push(fieldDisplayNames[field]);  // Use display names
      }
    });

    return { isValid, missingFields };
  };


  const validate2ndForm = () => {
    let isValid2nd = true;
    let missingFields2nd = [];
    if (isChecked1st) {

      requiredforSecondFields.forEach((field) => {
        if (!formData[field]) {
          isValid2nd = false;
          missingFields2nd.push(fieldDisplayNames[field]);  // Use display names
        }
      });
    }

    return { isValid2nd, missingFields2nd };
  };


  const validateEmail = (email) => {
    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'companyName') {
      // Capitalize the first letter of each word for companyName
      const uppercaseValue = value.toUpperCase();
      setFormData(prevData => ({ ...prevData, [name]: uppercaseValue }));
    } else if (['representative', 'companyName', 'personName', 'personName2nd'].includes(name)) {
      // Ensure only alphabetic characters for these fields
      const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, '');
      setFormData(prevData => ({ ...prevData, [name]: alphabeticValue }));
    }

    else if (name === 'mobileNumber') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    } else if (name === 'mobileNumber2nd') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    }

    else {
      // For other fields, just update the state as usual
      setFormData(prevData => ({ ...prevData, [name]: value }));
    }
  };

  useEffect(() => {
    if (formData.mobileNumber && formData.mobileNumber.length === 10) {

      axios.get(`${process.env.REACT_APP_API_END_POINT}/check-number-is-present/${formData.mobileNumber}`).then((resData) => {

        setFristMobileNumberError(resData.data)
      }).catch((err) => {
        setFristMobileNumberError(err.response.data)

      })
    }

  }, [
    formData.mobileNumber
  ])

  useEffect(() => {
    if (formData.mobileNumber2nd && formData.mobileNumber2nd.length === 10) {

      axios.get(`${process.env.REACT_APP_API_END_POINT}/check-number-is-present/${formData.mobileNumber2nd}`).then((resData) => {
        setSecondMobileNumberError(resData.data)

      }).catch((err) => {
        setSecondMobileNumberError(err.response.data)

      })
    }

  }, [
    formData.mobileNumber2nd
  ])


  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value) {

      if (name === 'emailAddress' && !validateEmail(value)) {
        alert('This is not a correct email address');
        setFormData(prevData => ({ ...prevData, [name]: '' })); // Optionally clear the field
      }
      if (name === 'emailAddress2nd' && !validateEmail(value)) {
        alert('This is not a correct email address');
        setFormData(prevData => ({ ...prevData, ["emailAddress2nd"]: '' })); // Optionally clear the field
      }
    }
  };


  useEffect(() => {
    console.log("hello i am here to see the test daeeed", gst_number?.length);

    if (gst_number?.length === 15 && checkRegex(gst_number)) {
      // setReloadShow(true);
      // ;

      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/check-gst-validation/${gst_number}`
        )
        .then((resData) => {
          // setReloadShow(false);
          // console.log(
          //   "hello i am here to see the test daeeed",
          //   resData.data.message,
          //   resData.data.data.lgnm,
          //   resData.data.data.pradr.adr
          // );
          // if (resData.data.message === "GSTIN  found.") {
          console.log("gst find or not", resData.data);
          if (resData.data.flag === false) {
            setShowError(resData.data.message);
            return;
          }
          setShowError("");
          setGst_data_present(resData.data)
          setFormData({ ...formData, companyName: resData.data.company });

          setCompany_address(resData.data.address);
          setCompany_city(resData.data.city);
          setCompany_state(resData.data.state)
          setCompany_pincode(resData.data.pincode)
          // }
        });
    }
  }, [gst_number]);
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Before sending to the API, ensure `designation` holds the correct value


    setFormData({
      ...formData, designation: formData.designation === "others"
        ? formData.otherDesignation
        : formData.designation
    });
    if (formData.roomOccupancy === "Double") {

      setFormData({
        ...formData, designation2nd: formData.designation2nd === "others"
          ? formData.otherDesignation2nd
          : formData.designation2nd
      });
    }


    if (!isChecked) {
      return notification.error({
        message: "Accept the guidelines",
        description: "",
      });
    }

    // if (formData?.mobileNumber?.length !== 10) {
    //   console.log("1st number", formData?.mobileNumber?.length)
    //   return message.error(`Enter 10 Digit Number`)
    // }
    if (isChecked1st && formData?.mobileNumber2nd?.length !== 10) {
      console.log("2nd number", formData?.mobileNumber2nd?.length)

      return message.error(`Enter 10 Digit Number`)
    }
    // const { isValid2nd, missingFields2nd } = validate2ndForm();

    // const { isValid, missingFields } = validateForm();

    { error_1st && <span style={{ color: 'red' }}>{dateValidation}</span> }

    // if (!isValid) {
    //   alert(`Please fill the following required fields: ${missingFields.join(', ')}`);
    // } else if (
    //   isChecked1st && !isValid2nd
    // ) {
    //   alert(`Please fill the following required fields: ${missingFields2nd.join(', ')}`);

    // } else
    setLoader(true)

    if (!isDateInRange(hotelCheckInDate) || !isDateInRange(hotelCheckOutDate)) {
      setLoader(false)

      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
    } else if ((!isDateInRange(departureDate))) {
      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} Arrival date`);

      setLoader(false)

    }
    else if ((isChecked1st && !isChecked2nd) && !isDateInRange(departureDate2nd)) {
      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} Arrival date of second person`);
      setLoader(false)


    }
    else if (!isDateInRange(returnFlightDate)) {
      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} return date`);
      setLoader(false)


    }




    else if ((isChecked1st && !isChecked3rd) && !isDateInRange(returnFlightDate2nd)) {
      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} return date of second person`);

      setLoader(false)

    }


    //   else if (
    //     extractedPart === `gcc-retailer-registration-form` && !formData.country
    //   ) {
    //     message.error("Country is required for GCC Member");

    //   }
    //   // else if ((formData.ticketsDepartureBookedBy !== "Self" && formData.travelMode !== "Self") && (!formData.goaAirport)) {
    //   //   message.error("Please fill the required detail new");

    //   // }
    //   else if ((formData.ticketsDepartureBookedBy2nd !== "Self" && formData.travelMode2nd !== "Self") && isChecked1st && !isChecked2nd && (!formData.departureCity2nd || !formData.flightTrainName2nd || !formData.flightTrainNumber2nd || !formData.arrivalTimeGoa2nd || !formData.goaAirport2nd)) {
    //     message.error("Please fill the required detail new1");

    //   }
    //   else if ((formData.ticketsReturnBookedBy !== "Self" && formData.travelReturnMode !== "Self") && (!formData.returnCity || !formData.returnFlightName || !formData.returnFlightNumber || !formData.returnFlightDepartureTime || !formData.returnAirport)) {
    //     message.error("Please fill the required detail new2");

    //   }
    //   else if ((formData.ticketsReturnBookedBy2nd !== "Self" && formData.travelReturnMode2nd !== "Self") && isChecked1st && !isChecked3rd && (!formData.returnCity2nd || !formData.returnFlightName2nd || !formData.returnFlightNumber2nd || !formData.returnFlightDepartureTime2nd || !formData.returnAirport2nd)) {
    //     message.error("Please fill the required detail new3");
    //   }
    //   else if (formData.ticketsDepartureBookedBy === "Self" && formData.travelMode !== "Self" && !formData.departurePNR) {
    //     alert(`Please fill the following required fields: Departure PNR`);

    //   }
    //   else if (formData.ticketsDepartureBookedBy2nd === "Self" && formData.travelMode2nd !== "Self" && isChecked1st && !isChecked2nd && !formData.departurePNR2nd) {
    //     alert(`Please fill the following required fields: Departure PNR of Second Person`);

    //   }
    //   else if (formData.ticketsReturnBookedBy === "Self" && formData.travelReturnMode !== "Self" && !formData.returnPNR) {
    //     alert(`Please fill the following required fields: Return PNR`);

    //   }
    //   else if (formData.ticketsReturnBookedBy2nd === "Self" && formData.travelReturnMode2nd !== "Self" && isChecked1st && !isChecked3rd && !formData.returnPNR2nd) {
    //     alert(`Please fill the following required fields: Return PNR of Second Person`);

    //   }

    // else if (isChecked1st && (!isDateInRange(hotelCheckInDate2nd) || !isDateInRange(hotelCheckOutDate2nd))) {
    //   message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);

    // }

    else {
      console.log("hello finalDesignation", departureDate,
        hotelCheckInDate,
        hotelCheckOutDate,
        returnFlightDate,
        departureDate2nd,
        returnFlightDate2nd);
      const dataSend = new FormData();

      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          dataSend.append(key, formData[key]);
        }
      }

      // dataSend.append("departureDate", departureDate);
      // dataSend.append("returnFlightDate", returnFlightDate);
      // dataSend.append("departureDate2nd", departureDate2nd);
      // dataSend.append("returnFlightDate2nd", returnFlightDate2nd);

      // dataSend.append("hotelCheckInDate", hotelCheckInDate || "")
      // dataSend.append("hotelCheckOutDate", hotelCheckOutDate || "")
      // dataSend.append("hotelCheckInDate2nd", hotelCheckInDate2nd || "")
      // dataSend.append("hotelCheckOutDate2nd", hotelCheckOutDate2nd || "")
      if (departureDate) {
        dataSend.append("departureDate", departureDate);

      } else {
        // Handle the case where departureDate is not present (if needed)
        console.log("departureDate is not provided.");
      }

      if (returnFlightDate) {
        dataSend.append("returnFlightDate", returnFlightDate);
      } else {
        console.log("returnFlightDate is not provided.");
      }

      if (departureDate2nd) {
        dataSend.append("departureDate2nd", departureDate2nd);
      } else {
        console.log("departureDate2nd is not provided.");
      }

      if (returnFlightDate2nd) {
        dataSend.append("returnFlightDate2nd", returnFlightDate2nd);
      } else {
        console.log("returnFlightDate2nd is not provided.");
      }

      if (hotelCheckInDate) {
        dataSend.append("hotelCheckInDate", hotelCheckInDate);
      } else {
        dataSend.append("hotelCheckInDate", ""); // Append empty string if not present
      }

      if (hotelCheckOutDate) {
        dataSend.append("hotelCheckOutDate", hotelCheckOutDate);
      } else {
        dataSend.append("hotelCheckOutDate", ""); // Append empty string if not present
      }

      if (hotelCheckInDate2nd) {
        dataSend.append("hotelCheckInDate2nd", hotelCheckInDate2nd);
      } else {
        dataSend.append("hotelCheckInDate2nd", ""); // Append empty string if not present
      }

      if (hotelCheckOutDate2nd) {
        dataSend.append("hotelCheckOutDate2nd", hotelCheckOutDate2nd);
      } else {
        dataSend.append("hotelCheckOutDate2nd", ""); // Append empty string if not present
      }

      dataSend.append("isChecked1st", isChecked1st || "")
      dataSend.append("isChecked2nd", isChecked2nd || "")
      dataSend.append("isChecked3rd", isChecked3rd || "")
      dataSend.append("gst_number", gst_number || "")
      dataSend.append("company_address", company_address || "")
      dataSend.append("company_city", company_city || "")
      dataSend.append("company_state", company_state || "")
      dataSend.append("company_pincode", company_pincode || "")
      dataSend.append("no_of_stores", no_of_stores || "")
      dataSend.append("sqft_area", sqft_area || "")
      dataSend.append("no_of_staff", no_of_staff || "")
      // dataSend.append("company_category", company_category || "")
      dataSend.append("categories", JSON.stringify(categories))
      dataSend.append("other_category", JSON.stringify(other_category))

      dataSend.append("photo", photo)
      dataSend.append("id_proof_front", idProofFront)
      dataSend.append("id_proof_back", idProofBack)
      dataSend.append("second_photo", second_photo)
      dataSend.append("second_id_proof_front", second_idProofFront)
      dataSend.append("second_id_proof_back", second_idProofBack)
      dataSend.append("isChecked", isChecked)

      // 


      // return
      axios.post(`${process.env.REACT_APP_API_END_POINT}/retailers-detail`, dataSend

      ).then((resData) => {
        // Show success message
        // message.success("Form Submitted Successfully");
        localStorage.removeItem('barcode')
        setLoader(false)
        localStorage.removeItem('password_form')
        localStorage.removeItem('from_status')
        navigate("/thank-you")
        notification.success({
          message: "Form Submitted Successfully",
          description: "",
        });
      })

        .catch((error) => {
          console.error("Error occurred while submitting the form:", error);


        })
        .finally(() => {
          // Reload the page after 1 second
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 500); // 500 milliseconds = 0.5 second
        });
    }
  };

  useEffect(() => {
    if (hotelCheckInDate) {
      console.log("hello", hotelCheckInDate, isDateInRange(hotelCheckInDate));
      if (!isDateInRange(hotelCheckInDate)) {
        set_error_5th(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_5th(false)

      }
    }
  }, [hotelCheckInDate]);

  useEffect(() => {
    if (hotelCheckOutDate) {
      console.log("hello", hotelCheckOutDate, isDateInRange(hotelCheckOutDate));
      if (!isDateInRange(hotelCheckOutDate)) {
        set_error_6th(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_6th(false)

      }
    }
  }, [hotelCheckOutDate]);


  useEffect(() => {
    if (departureDate) {
      console.log("hello", departureDate, isDateInRange(departureDate));
      if (!isDateInRange(departureDate)) {
        set_error_1st(true)

        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_1st(false)

      }
    }
  }, [departureDate]);

  useEffect(() => {
    if (returnFlightDate) {
      console.log("hello", returnFlightDate, isDateInRange(returnFlightDate));
      if (!isDateInRange(returnFlightDate)) {
        set_error_3rd(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_3rd(false)

      }
    }
  }, [returnFlightDate]);
  useEffect(() => {
    if (hotelCheckInDate2nd) {
      console.log("hello", hotelCheckInDate2nd, isDateInRange(hotelCheckInDate2nd));
      if (!isDateInRange(hotelCheckInDate2nd)) {
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      }
    }
  }, [hotelCheckInDate2nd]);

  useEffect(() => {
    if (hotelCheckOutDate2nd) {
      console.log("hello", hotelCheckOutDate2nd, isDateInRange(hotelCheckOutDate2nd));
      if (!isDateInRange(hotelCheckOutDate2nd)) {
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      }
    }
  }, [hotelCheckOutDate2nd]);


  useEffect(() => {
    if (departureDate2nd) {
      console.log("hello", departureDate2nd, isDateInRange(departureDate2nd));
      if (!isDateInRange(departureDate2nd)) {
        set_error_2nd(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_2nd(false)

      }
    }
  }, [departureDate2nd]);

  useEffect(() => {
    if (returnFlightDate2nd) {
      console.log("hello", returnFlightDate2nd, isDateInRange(returnFlightDate2nd));
      if (!isDateInRange(returnFlightDate2nd)) {
        set_error_4th(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_4th(false)

      }
    }
  }, [returnFlightDate2nd]);

  useEffect(() => {
    if (formData.ticketsDepartureBookedBy === "IGI") {

      setTravelModerarrivalArray(["Train", "Flight"])
    } else {
      setTravelModerarrivalArray(["Train", "Flight", "Self"])

    }
  }, [formData.ticketsDepartureBookedBy])

  useEffect(() => {
    if (formData.ticketsReturnBookedBy === "IGI") {

      setTravelModerDepartureArray(["Train", "Flight"])
    } else {

      setTravelModerDepartureArray(["Train", "Flight", "Self"])
    }
  }, [formData.ticketsReturnBookedBy])

  useEffect(() => {
    if (formData.ticketsDepartureBookedBy2nd === "IGI") {
      setTravelModerarrivalArray2nd(["Train", "Flight"])

    } else {
      setTravelModerarrivalArray2nd(["Train", "Flight", "Self"])

    }
  }, [formData.ticketsDepartureBookedBy2nd])

  useEffect(() => {
    if (formData.ticketsReturnBookedBy2nd === "IGI") {

      setTravelModerDepartureArray2nd(["Train", "Flight"])
    } else {
      setTravelModerDepartureArray2nd(["Train", "Flight", "Self"])

    }
  }, [formData.ticketsReturnBookedBy2nd])
  // 
  useEffect(() => {
    if (extractedPart === `gcc-retailer-registration-form`) {
      setRepresentative_list(['Sadiye'])

    }
  }, [])
  const handleCheckboxChange = (data, setData) => {
    setData(!data); // Toggle the state
  };
  useEffect(() => {
    if (isChecked1st) {
      setIsChecked2nd(true)
      setIsChecked3rd(true)

    }
  }, [isChecked1st])
  useEffect(() => {

    if (formData.roomOccupancy === "Single") {
      setIsChecked1st(false)
      setIsChecked2nd(true)
      setIsChecked3rd(true)
      setShowBlock3rd(false)
      setShowBlock4th(false)

    }
  }, [formData.roomOccupancy])





  // Handle input change for percentage
  const handlePercentageChange = (id, value) => {
    const updatedCategories = categories.map((category) =>
      category.id === id ? { ...category, percentage: value } : category
    );

    setCategories(updatedCategories);
    validatePercentages(updatedCategories);
  };

  // Validate if the sum of percentages equals 100
  const validatePercentages = (updatedCategories) => {
    const totalPercentage = updatedCategories.reduce((sum, category) => {
      return sum + (parseFloat(category.percentage) || 0);
    }, 0);

    if (totalPercentage !== 100) {
      setError('The total percentage must equal 100.');
    } else {
      setError('');
    }
  };

  const calculateRemainingPercentage = (categories) => {
    const totalPercentage = categories.reduce((sum, category) => {
      return sum + (parseFloat(category.percentage) || 0);
    }, 0);

    const remainingPercentage = 100 - totalPercentage;

    return remainingPercentage > 0 ? remainingPercentage : 0;
  };

  const [other_category, setOtherCategory] = useState([
    { id: 1, key: 'Antique Gold Jewellery', value: '' },
    { id: 2, key: 'Couture Jewellery', value: '' },
    { id: 3, key: 'Diamond Jewellery', value: '' },
    { id: 4, key: 'Generic Gold Jewellery', value: '' },
    { id: 6, key: 'Allied/Loose Stone/Lab Grown', value: '' },
  ]);
  const [other_error, setOtherError] = useState('');

  const handlePriorityChange = (index, value) => {
    const numValue = parseInt(value, 10);
    const updatedCategories = other_category.map((category, i) =>
      i === index ? { ...category, value: numValue } : category
    );

    setOtherCategory(updatedCategories);
    validatePriorities(updatedCategories);
  };

  const validatePriorities = (updatedCategories) => {
    const assignedPriorities = updatedCategories
      .map((category) => parseInt(category.value, 10))
      .filter((value) => !isNaN(value));

    const uniquePriorities = new Set(assignedPriorities);
    const maxPriority = Math.max(...Array.from(uniquePriorities), 0);

    // Error messages
    let errorMessage = '';

    // Case 1: Max priority should not be less than 4
    if (maxPriority < 4) {
      errorMessage = 'The maximum priority should be at least 4.';
    }
    // Case 2: Ensure all numbers from 1 to maxPriority are present
    else if (Array.from({ length: maxPriority }, (_, i) => i + 1).some(num => !uniquePriorities.has(num))) {
      errorMessage = `All priorities from 1 to ${maxPriority} must be filled sequentially.`;
    }
    // Case 3: Check for unique priorities
    else if (uniquePriorities.size !== assignedPriorities.length) {
      errorMessage = 'Each priority must be unique.';
    }

    // Set the error message
    if (errorMessage) {
      setOtherError(errorMessage);
    } else {
      setOtherError('');
    }
  };

  // const addCategory = () => {
  //   if (other_category.length < 4) {
  //     setOtherCategory([
  //       ...other_category,
  //       { key: `New Category ${other_category.length + 1}`, value: other_category.length },
  //     ]);
  //   }
  // };


  function handlePhoto(e) {
    setPhoto(e.target.files[0]);
  }
  function handleIDFront(e) {
    setIDProofFront(e.target.files[0]);
  }
  function handleIDBack(e) {
    setIDProofBack(e.target.files[0]);
  }


  const dateValidation = `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`
  return (
    <>
      <div>
        <div className="text-center">
          <h4 className="form-heading">{extractedPart === `gcc-retailer-registration-form` ? "GCC Retailers Detail Form" : "Buyer Detail Form"}</h4>
        </div>

        <section>

          <div className="container">
            <div className="form-body-section">

              <div className="form-body">
                <form onSubmit={handleSubmit}>


                  <div className="row">
                    {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Representative <font color="#FF0000">*</font>

                      </label>
                      <Select
                        className="my-2"
                        options={representative_list.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        required
                        value={
                          formData.representative
                            ? { value: formData.representative, label: formData.representative }
                            : null
                        }
                        onChange={(selectedOption) => setFormData({ ...formData, representative: selectedOption ? selectedOption.value : null })}


                        isSearchable
                        placeholder="Select Representative"
                      />
                 
                    </div> */}
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Gst Number <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="GST Number"
                        required
                        value={gst_number}

                        name="companyName"
                        onChange={handleGstNumberChange}
                      />
                      {showError && <p className="text-danger">{showError}</p>}
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Name of the Company <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Company Name"
                        required
                        disabled={gst_data_present?.company}
                        value={formData.companyName}

                        name="companyName"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Company Address <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Address"
                        required
                        disabled={gst_data_present?.address}
                        value={company_address}

                        name="companyName"
                        onChange={(e) => { setCompany_address(e.target.value) }}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        City <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="city"
                        required
                        disabled={gst_data_present?.city}
                        value={company_city}

                        name="companyName"
                        onChange={(e) => { setCompany_city(e.target.value) }}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        State <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="State"
                        required
                        disabled={gst_data_present?.state}
                        value={company_state}

                        name="companyName"
                        onChange={(e) => { setCompany_state(e.target.value) }}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Company Pincode <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Company Pincode"
                        required
                        disabled={gst_data_present?.pincode}
                        value={company_pincode}

                        name="companyName"
                        onChange={(e) => { setCompany_pincode(e.target.value) }}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Number of Stores <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Number of stores"
                        required
                        value={no_of_stores}
                        // maxLength={2}

                        name="companyName"
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^0-9]/g, '')
                          if (/^\d*$/.test(value)) { // Allow only digits
                            set_no_of_stores(value);
                          }
                        }}

                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Total Square feet area <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Area in sqft"
                        required
                        value={sqft_area}
                        maxLength={6}

                        name="companyName"
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^0-9]/g, '')
                          if (/^\d*$/.test(value)) { // Allow only digits
                            set_sqft_area(value);
                          }
                        }}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        No. of staff <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="No of staff"
                        required
                        value={no_of_staff}
                        // maxLength={2}
                        name="companyName"
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^0-9]/g, '')
                          if (/^\d*$/.test(value)) { // Allow only digits
                            set_no_of_staff(value);
                            setShowBlock5th(true)
                          }
                        }}
                      />
                    </div>
                    {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Company Category <font color="#FF0000">*</font>
                      </label>
                      <Select
                        className="my-2"
                        options={company_category_array.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        value={
                          company_category
                            ? { value: company_category, label: company_category }
                            : null
                        }
                        required
                        onChange={(selectedOption) => { set_company_category(selectedOption.value); setShowBlock5th(true) }}

                        isSearchable
                        placeholder="Choose One"
                      />

                    </div> */}
                  </div>
                  {showBlock5th && <div className="row">


                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Category Percentage <font color="#FF0000">*</font>

                      </label>
                      <div>

                        <span>
                          Out of your 100% annual sales, kindly provide the ratio between Gold, Diamond & Others, this will help us in scheduling your meeting with the PMI participants.
                        </span>
                      </div>

                      <div className="table-responsive">
                        <Table className="table table-striped my-3 p-3 data-table">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Category</th>
                              <th scope="col">Percentage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {categories.map((category, index) => (
                              <tr key={category.id}>
                                <th scope="row">{index + 1}</th>
                                <td>{category.name}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={category.percentage}
                                    maxLength={2}
                                    onChange={(e) =>
                                      handlePercentageChange(category.id, e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      {error && <span style={{ color: 'red' }}>


                        {error} -: still {calculateRemainingPercentage(categories)} remaining percentage
                      </span>
                      }
                    </div>
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Category Priority <font color="#FF0000">*</font>
                      </label>
                      <div>

                        <span>
                          Please specify your priority (1 being first and 5 being last priority) for creating your meeting schedule at PMI
                        </span>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-striped my-3 p-3 data-table">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Category</th>
                              <th scope="col">Priority</th>
                            </tr>
                          </thead>
                          <tbody>
                            {other_category.map((category, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{category.key}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    maxLength={1}
                                    min="0"
                                    value={category.value}
                                    onChange={(e) => {
                                      handlePriorityChange(index, e.target.value);


                                      setShowBlock2nd(true)
                                    }
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {other_error && <span style={{ color: 'red' }}>{other_error}</span>}


                      </div>
                    </div>
                    {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Room Occupancy
                      </label>
                      <Select
                        className="my-2"
                        required
                        options={occupancy_list.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        value={
                          formData.roomOccupancy
                            ? { value: formData.roomOccupancy, label: formData.roomOccupancy }
                            : null
                        }
                        onChange={(selectedOption) => { setFormData({ ...formData, roomOccupancy: selectedOption ? selectedOption.value : null }); setShowBlock2nd(true); }}


                        isSearchable
                        placeholder="Select designation"
                      />

                    </div> */}


                  </div>}

                  <div className="row">
                    {showBlock2nd &&
                      <>

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Title <font color="#FF0000">*</font>
                          </label>
                          <Select
                            className="my-2"
                            options={titleData.map((data) => ({
                              value: data,
                              label: data,
                            }))}
                            required={formData.title !== "others" ? true : false}
                            value={
                              formData.title && formData.title !== "others"
                                ? { value: formData.title, label: formData.title }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setFormData({ ...formData, title: selectedOption.value });
                            }}
                            isSearchable
                            placeholder="Select designation"
                          />
                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Name of the Person <font color="#FF0000">*</font>
                          </label>
                          <input
                            required
                            className="form-control my-2"
                            placeholder="Person Name"
                            value={formData.personName}

                            name="personName"
                            onChange={handleChange}
                          />

                        </div>


                        {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Room No
                        <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Room No"
                        value={formData.roomNo}

                        name="roomNo"
                        onChange={handleChange}
                      />
                    </div> */}

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Mobile number <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            required
                            placeholder="Mobile number"
                            value={formData.mobileNumber}
                            name="mobileNumber"
                            onChange={handleChange}
                            disabled
                          />
                          {/* {fristMobileNumberError && <div style={{ color: fristMobileNumberError.already_exists ? "red" : "green", fontSize: '12px', marginBottom: '10px' }}>
                            {fristMobileNumberError.already_exists ? "The number is already taken and not available." : fristMobileNumberError.message}
                          </div>
                          } */}
                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="emailAddress"
                            className="form-control my-2"
                            value={formData.emailAddress}
                            onChange={handleChange}
                            onBlur={handleBlur} // Add onBlur event handler
                            placeholder="Email Address"
                          />
                          {/* <input
                        value={formData.emailAddress}

                        className="form-control my-2"
                        placeholder="Email Address"
                        name="emailAddress"
                        onChange={handleChange}
                      /> */}
                        </div>



                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            title_2nd <font color="#FF0000">*</font>
                          </label>


                          {/* Show the text field only if "others" is selected */}
                          {formData.designation === "others" ? (
                            <input
                              type="text"
                              required
                              className="form-control my-2"
                              placeholder="Enter your designation"
                              value={formData.otherDesignation}
                              onChange={(e) => { handleOtherDesignationChange(e, '1st'); }}
                            />
                          ) :

                            (
                              <Select
                                className="my-2"
                                options={designationArray.map((data) => ({
                                  value: data,
                                  label: data,
                                }))}
                                required={formData.designation !== "others" ? true : false}
                                value={
                                  formData.designation && formData.designation !== "others"
                                    ? { value: formData.designation, label: formData.designation }
                                    : null
                                }
                                onChange={(e) => { handleSelectChange(e, '1st'); setShowBlock3rd(true); }}
                                isSearchable
                                placeholder="Select designation"
                              />
                            )}
                        </div>
                        <div className="col-lg-4 col-12 regis-form-group">
                          <label className="fw-bold">
                            Photo <font color="#FF0000">*</font>
                          </label>
                          <CommonFileUploader handlePhoto={(e) => setPhoto(e.target.files[0])} fileName={photo ? photo?.name : "Select File"}
                            duplicateError={duplicateError}
                            accept=".jpg, .jpeg, .png"
                          />


                        </div>
                        <div className="col-lg-4 col-12 regis-form-group">
                          <label className="fw-bold">
                            Upload ID Front(Aadhar Card) <font color="#FF0000">*</font>
                          </label>
                          <CommonFileUploader handlePhoto={(e) => setIDProofFront(e.target.files[0])} fileName={idProofFront ? idProofFront?.name : "Select File"}
                            duplicateError={duplicateError}
                            accept=".jpg, .jpeg, .png"
                          />


                        </div>
                        <div className="col-lg-4 col-12 regis-form-group">
                          <label className="fw-bold">
                            Upload ID Back(Aadhar Card) <font color="#FF0000">*</font>
                          </label>
                          <CommonFileUploader handlePhoto={(e) => setIDProofBack(e.target.files[0])} fileName={idProofBack ? idProofBack?.name : "Select File"}
                            duplicateError={duplicateError}
                            accept=".jpg, .jpeg, .png"
                          />


                        </div>

                        <div className='my-3 ms-2' >
                          <label>
                            <input
                              type="checkbox"
                              checked={isChecked1st}
                              onChange={() => handleCheckboxChange(isChecked1st, setIsChecked1st)}
                            />
                            <span className='ms-3'>
                              Click Here to add Second Person
                            </span>
                          </label>
                          {/* <p>{isChecked1st ? "Checkbox is checked" : "Checkbox is unchecked"}</p> */}
                        </div>

                        {isChecked1st &&
                          <div className="row">
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Title <font color="#FF0000">*</font>
                              </label>
                              <Select
                                className="my-2"
                                options={titleData.map((data) => ({
                                  value: data,
                                  label: data,
                                }))}
                                required={formData.title_2nd !== "others" ? true : false}
                                value={
                                  formData.title_2nd && formData.title_2nd !== "others"
                                    ? { value: formData.title_2nd, label: formData.title_2nd }
                                    : null
                                }
                                onChange={(selectedOption) => { setFormData({ ...formData, title_2nd: selectedOption.value }) }}

                                isSearchable
                                placeholder="Select designation"
                              />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Name of the 2nd Person <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                placeholder="Person Name"
                                required={isChecked1st}
                                value={formData.personName2nd}

                                name="personName2nd"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Mobile number of 2nd Person <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                placeholder="Mobile number"
                                value={formData.mobileNumber2nd}
                                required={isChecked1st}

                                name="mobileNumber2nd"
                                onChange={handleChange}
                              />
                              {secondMobileNumberError && <div style={{ color: secondMobileNumberError.already_exists ? "red" : "green", fontSize: '12px', marginBottom: '10px' }}>
                                {secondMobileNumberError.already_exists ? "The number is already taken and not available." : secondMobileNumberError.message}
                              </div>}
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Email Address  of 2nd Person
                              </label>
                              <input
                                type="email"
                                name="emailAddress2nd"
                                className="form-control my-2"
                                value={formData.emailAddress2nd}
                                onChange={handleChange}

                                onBlur={handleBlur} // Add onBlur event handler
                                placeholder="Email Address"
                              />
                              {/* <input
                             value={formData.emailAddress}
     
                             className="form-control my-2"
                             placeholder="Email Address"
                             name="emailAddress"
                             onChange={handleChange}
                           /> */}
                            </div>
                            <div className="col-lg-4 col-12 regis-form-group">
                              <label className="fw-bold">
                                Upload Photo <font color="#FF0000">*</font>
                              </label>
                              <CommonFileUploader handlePhoto={(e) => set_second_photo(e.target.files[0])} fileName={second_photo ? second_photo?.name : "Select File"}
                                duplicateError={duplicateError}
                                accept=".jpg, .jpeg, .png"
                              />


                            </div>
                            <div className="col-lg-4 col-12 regis-form-group">
                              <label className="fw-bold">
                                Upload ID Front(Aadhar Card) <font color="#FF0000">*</font>
                              </label>
                              <CommonFileUploader handlePhoto={(e) => set_second_idProofFront(e.target.files[0])} fileName={second_idProofFront ? second_idProofFront?.name : "Select File"}
                                duplicateError={duplicateError}
                                accept=".jpg, .jpeg, .png"
                              />


                            </div>
                            <div className="col-lg-4 col-12 regis-form-group">
                              <label className="fw-bold">
                                Upload ID Back(Aadhar Card) <font color="#FF0000">*</font>
                              </label>
                              <CommonFileUploader handlePhoto={(e) => set_second_idProofBack(e.target.files[0])} fileName={second_idProofBack ? second_idProofBack?.name : "Select File"}
                                duplicateError={duplicateError}
                                accept=".jpg, .jpeg, .png"
                              />


                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Designation  of 2nd Person<font color="#FF0000">*</font>
                              </label>


                              {/* Show the text field only if "others" is selected */}
                              {formData.designation2nd === "others" ? (
                                <input
                                  type="text"
                                  required

                                  className="form-control my-2"
                                  placeholder="Enter your designation"
                                  value={formData.otherDesignation2nd}
                                  onChange={(e) => handleOtherDesignationChange(e, '2nd')}
                                />
                              ) :

                                (
                                  <Select
                                    className="my-2"
                                    options={designationArray.map((data) => ({
                                      value: data,
                                      label: data,
                                    }))}
                                    required={(isChecked1st && formData.designation !== "others") ? true : false}
                                    value={
                                      formData.designation2nd && formData.designation2nd !== "others"
                                        ? { value: formData.designation2nd, label: formData.designation2nd }
                                        : null
                                    }
                                    onChange={(e) => handleSelectChange(e, '2nd')}
                                    isSearchable
                                    placeholder="Select designation"
                                  />
                                )}
                            </div>
                          </div>
                        } </>
                    }

                  </div>

                  {/* {
                    <div className="row">
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Hotel Check-In Date of 2nd Preson <font color="#FF0000">*</font>
                        </label>
                        <DatePickers
                          setDate={setHotelCheckInDate2nd} lable={'Hotel Check-In Date (2nd Person)'}

                        />
                      </div>
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Hotel Check-Out Date of 2nd Preson <font color="#FF0000">*</font>
                        </label>
                        <DatePickers
                          setDate={setHotelCheckOutDate2nd} lable={'Hotel Check-Out Date (2nd Person)'}

                        />
                      </div>

                    </div>}
                  <div className="text-center">
                    <h4 className="form-heading">{"Travel Detail"}</h4>
                  </div>
                  <hr /> */}

                  {
                    showBlock3rd &&

                    <>
                      <div className="text-center ">
                        <h4 className="form-heading">{"Arrival Detail"}</h4>
                      </div>
                      <div className="text-center ">
                        <span >{"Arrival City Chennai"}</span>
                      </div>
                      <hr />
                      <div className="row">

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Travel Mode <font color="#FF0000">*</font>
                          </label>
                          <Select
                            className="my-2"
                            options={travelModerarrivalArray.map((data) => ({
                              value: data,
                              label: data,
                            }))}
                            value={
                              formData.travelMode
                                ? { value: formData.travelMode, label: formData.travelMode }
                                : null
                            }
                            required
                            onChange={(selectedOption) => { setFormData({ ...formData, travelMode: selectedOption ? selectedOption.value : null }); setShowBlock4th(true); }}

                            isSearchable
                            placeholder="Choose One"
                          />

                        </div>

                        {formData.travelMode === "Self" ? null :

                          <>

                            {/* <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Arrival PNR <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.departurePNR}
                                required

                                placeholder="Arrival PNR"
                                name="departurePNR"
                                onChange={handleChange}
                              />
                            </div> */}
                            <div className="form-group col-lg-4 col-md-6">

                              <label className="fw-bold">
                                Departure City (Arriving From Which City) <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.departureCity}
                                required
                                placeholder="Arrival City"
                                name="departureCity"
                                onChange={handleChange}
                              />
                            </div>

                          </>}

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Date of Arrival <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={departureDate} setSelectedDate={setDepartureDate} lable={'Date of Departure'} required={true}

                          />
                          {error_1st && <span style={{ color: 'red' }}>{dateValidation}</span>}
                        </div>

                        {formData.travelMode === "Self" ? null : <>

                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train name <font color="#FF0000">*</font>
                            </label>
                            <input
                              className="form-control my-2"
                              value={formData.flightTrainName}
                              required
                              placeholder="Flight/Train name"
                              name="flightTrainName"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train Number <font color="#FF0000">*</font>
                            </label>
                            <input
                              className="form-control my-2"
                              placeholder="Flight/Train Number"
                              value={formData.flightTrainNumber}
                              required
                              name="flightTrainNumber"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Arrival time at Chennai <font color="#FF0000">*</font>
                            </label>
                            <input
                              // type="text"
                              type="time"
                              className="form-control my-2"
                              placeholder="Arrival Time"
                              value={formData.arrivalTimeGoa}
                              name="arrivalTimeGoa"
                              required
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Departure Time From Source <font color="#FF0000">*</font>
                            </label>
                            <input
                              // type="text"
                              type="time"
                              className="form-control my-2"
                              placeholder="Arrival Time"
                              value={formData.departure_time_from_source}
                              name="departure_time_from_source"
                              required
                              onChange={handleChange}
                            />
                          </div>

                        </>
                        }


                      </div>
                      {
                        isChecked1st && <div >
                          <label>
                            <input
                              type="checkbox"
                              checked={isChecked2nd}
                              onChange={() => handleCheckboxChange(isChecked2nd, setIsChecked2nd)}
                            />
                            <span className='ms-3'>

                              Add same details for {formData.personName2nd}
                            </span>
                          </label>
                          {/* <p>{isChecked1st ? "Checkbox is checked" : "Checkbox is unchecked"}</p> */}
                        </div>
                      }
                      <hr></hr>


                      {!isChecked2nd &&
                        <div className="row">

                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Travel Mode (2nd Person)<font color="#FF0000">*</font>
                            </label>
                            <Select
                              className="my-2"
                              options={travelModerarrivalArray2nd.map((data) => ({
                                value: data,
                                label: data,
                              }))}
                              value={
                                formData.travelMode2nd
                                  ? { value: formData.travelMode2nd, label: formData.travelMode2nd }
                                  : null
                              }
                              onChange={(selectedOption) => setFormData({ ...formData, travelMode2nd: selectedOption ? selectedOption.value : null })}
                              required


                              isSearchable
                              placeholder="Choose One"
                            />

                          </div>



                          {
                            formData.travelMode2nd === "Self" ? null :
                              <>
                                {/* <div className="form-group col-lg-4 col-md-6">
                                  <label className="fw-bold">
                                    Arrival PNR (2nd Person)<font color="#FF0000">*</font>
                                  </label>
                                  <input
                                    className="form-control my-2"
                                    value={formData.departurePNR2nd}
                                    required

                                    placeholder="Arrival PNR"
                                    name="departurePNR2nd"
                                    onChange={handleChange}
                                  />
                                </div> */}
                                <div className="form-group col-lg-4 col-md-6">

                                  <label className="fw-bold">
                                    Departure City 2nd Person(Arriving From Which City))<font color="#FF0000">*</font>
                                  </label>
                                  <input
                                    className="form-control my-2"
                                    value={formData.departureCity2nd}
                                    required

                                    placeholder="Arrival City"
                                    name="departureCity2nd"
                                    onChange={handleChange}
                                  />
                                </div>
                              </>}

                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Date of Arrival (2nd Person)<font color="#FF0000">*</font>
                            </label>
                            <DatePickers
                              selectedDate={departureDate2nd} setSelectedDate={setDepartureDate2nd} lable={'Date of Arrival'}
                              required={true}

                            />
                            {error_2nd && <span style={{ color: 'red' }}>{dateValidation}</span>}

                          </div>
                          {
                            formData.travelMode2nd === "Self" ? null : <>



                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Flight/Train name (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  className="form-control my-2"
                                  value={formData.flightTrainName2nd}
                                  required

                                  placeholder="Flight/Train name"
                                  name="flightTrainName2nd"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Flight/Train Number (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  className="form-control my-2"
                                  placeholder="Flight/Train Number"
                                  value={formData.flightTrainNumber2nd}
                                  required

                                  name="flightTrainNumber2nd"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Arrival time at Chennai (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  // type="text"
                                  required

                                  type="time"
                                  className="form-control my-2"
                                  placeholder="Arrival Time"
                                  value={formData.arrivalTimeGoa2nd}
                                  name="arrivalTimeGoa2nd"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Departure Time From Source <font color="#FF0000">*</font>
                                </label>
                                <input
                                  // type="text"
                                  type="time"
                                  className="form-control my-2"
                                  placeholder="Arrival Time"
                                  value={formData.departure_time_from_source_2nd}
                                  name="departure_time_from_source_2nd"
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                            </>
                          }


                        </div>}


                    </>
                  }

                  {
                    showBlock4th &&
                    <>
                      <div className="text-center">
                        <h4 className="form-heading">{"Returning Detail"}</h4>
                      </div>
                      <div className="text-center">
                        <span>{"Departure City Chennai"}</span>
                      </div>
                      <hr />

                      <div className="row">

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Travel Mode<font color="#FF0000">*</font>
                          </label>
                          <Select
                            className="my-2"
                            options={travelModerDepartureArray.map((data) => ({
                              value: data,
                              label: data,
                            }))}
                            value={
                              formData.travelReturnMode
                                ? { value: formData.travelReturnMode, label: formData.travelReturnMode }
                                : null
                            }
                            onChange={(selectedOption) => { setFormData({ ...formData, travelReturnMode: selectedOption ? selectedOption.value : null }); setShowBlock6th(true) }}

                            required
                            isSearchable
                            placeholder="Choose One"
                          />

                        </div>




                        {formData.travelReturnMode === "Self" ? null :
                          <>
                            {/* <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Returning PNR <font color="#FF0000">*</font>
                              </label>
                              <input
                                required
                                className="form-control my-2"
                                placeholder="Returning PNR"
                                value={formData.returnPNR}

                                name="returnPNR"
                                onChange={handleChange}
                              />
                            </div> */}

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Arrival City (Arriving In Which City) <font color="#FF0000">*</font>
                              </label>

                              <input
                                className="form-control my-2"
                                value={formData.returnCity}
                                required

                                placeholder="Returning City"
                                name="returnCity"
                                onChange={handleChange}
                              />
                            </div>
                          </>}

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            required={true}
                            selectedDate={returnFlightDate} setSelectedDate={setReturnFlightDate} lable={'Date of Departure'}

                          />
                          {error_3rd && <span style={{ color: 'red' }}>{dateValidation}</span>}

                        </div>
                        {formData.travelReturnMode === "Self" ? null : <>




                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train Name <font color="#FF0000">*</font>
                            </label>
                            <input
                              className="form-control my-2"
                              value={formData.returnFlightName}
                              required

                              placeholder="Flight/Train Name"
                              name="returnFlightName"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train Number <font color="#FF0000">*</font>
                            </label>
                            <input
                              className="form-control my-2"
                              value={formData.returnFlightNumber}
                              required

                              placeholder="Flight/Train Number"
                              name="returnFlightNumber"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train Time <font color="#FF0000">*</font>
                            </label>
                            <input
                              // type="text"
                              type="time"
                              required

                              className="form-control my-2"
                              placeholder="Returning Time"
                              value={formData.returnFlightDepartureTime}
                              name="returnFlightDepartureTime"
                              onChange={handleChange}
                            />
                          </div>




                        </>}

                      </div>
                      {
                        isChecked1st && <div >
                          <label>
                            <input
                              type="checkbox"
                              checked={isChecked3rd}
                              onChange={() => handleCheckboxChange(isChecked3rd, setIsChecked3rd)}
                            />
                            <span className='ms-3'>

                              Add same details for {formData.personName2nd}
                            </span>
                          </label>
                          {/* <p>{isChecked1st ? "Checkbox is checked" : "Checkbox is unchecked"}</p> */}
                        </div>
                      }
                      <hr></hr>

                      {!isChecked3rd &&
                        <div className="row">

                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Travel Mode (2nd Person)<font color="#FF0000">*</font>
                            </label>
                            <Select
                              className="my-2"
                              required

                              options={travelModerDepartureArray2nd.map((data) => ({
                                value: data,
                                label: data,
                              }))}
                              value={
                                formData.travelReturnMode2nd
                                  ? { value: formData.travelReturnMode2nd, label: formData.travelReturnMode2nd }
                                  : null
                              }
                              onChange={(selectedOption) => setFormData({ ...formData, travelReturnMode2nd: selectedOption ? selectedOption.value : null })}


                              isSearchable
                              placeholder="Choose One"
                            />

                          </div>


                          {formData.travelReturnMode2nd === "Self" ? null :
                            <>
                              {/* <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Returning PNR (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  className="form-control my-2"
                                  required

                                  placeholder="Returning PNR"
                                  value={formData.returnPNR2nd}

                                  name="returnPNR2nd"
                                  onChange={handleChange}
                                />
                              </div> */}
                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Arrival City 2nd Person (Arriving In Which City)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  className="form-control my-2"
                                  value={formData.returnCity2nd}
                                  required

                                  placeholder="Returning City"
                                  name="returnCity2nd"
                                  onChange={handleChange}
                                />
                              </div>
                            </>}
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train Date (2nd Person)<font color="#FF0000">*</font>
                            </label>
                            <DatePickers
                              selectedDate={returnFlightDate2nd} setSelectedDate={setReturnFlightDate2nd} lable={'Date of Departure'}

                            />
                            {error_4th && <span style={{ color: 'red' }}>{dateValidation}</span>}

                          </div>
                          {formData.travelReturnMode2nd === "Self" ? null : <>



                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Name (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.returnFlightName2nd}
                                required

                                placeholder="Flight/Train Name"
                                name="returnFlightName2nd"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Number (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.returnFlightNumber2nd}
                                required

                                placeholder="Flight/Train Number"
                                name="returnFlightNumber2nd"
                                onChange={handleChange}
                              />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Time (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                // type="text"
                                type="time"
                                required

                                className="form-control my-2"
                                placeholder="Returning Time"
                                value={formData.returnFlightDepartureTime2nd}
                                name="returnFlightDepartureTime2nd"
                                onChange={handleChange}
                              />
                            </div>



                          </>}

                        </div>


                      }
                    </>
                  }

                  {showBlock6th &&
                    <>

                      <div className="text-center">
                        <h4 className="form-heading">{"Hotel Detail"}</h4>

                      </div>
                      <div className="text-center my-1 ">

                        <h5 className='font-weight-bold'>ITC Grand Chola, a Luxury Collection Hotel, Chennai</h5>
                      </div>
                      <hr />
                      <div className="row my-3">
                        <div className="form-group col-lg-6 col-md-6">
                          <label className="fw-bold">
                            Hotel Check-In Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={hotelCheckInDate} setSelectedDate={setHotelCheckInDate} lable={'Hotel Check-In Date (2nd Person)'} required={true}

                          />
                          {error_5th && <span style={{ color: 'red' }}>{dateValidation}</span>}

                        </div>
                        <div className="form-group col-lg-6 col-md-6" >
                          <label className="fw-bold">
                            Hotel Check-Out Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={hotelCheckOutDate} setSelectedDate={setHotelCheckOutDate} lable={'Hotel Check-Out Date (2nd Person)'}
                            required={true}

                          />
                          {error_6th && <span style={{ color: 'red' }}>{dateValidation}</span>}

                        </div>

                      </div>
                    </>
                  }
                  <Tandcform />
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      id="acceptGuidelines"
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                    />
                    <label htmlFor="acceptGuidelines" className='ms-1'>
                      I agree to the above mentioned Guidelines for PMI
                    </label>
                  </div>
                  <div className="text-center my-4">
                    <button type="submit" className="btn btn-primary" disabled={

                      (secondMobileNumberError && secondMobileNumberError?.already_exists) || error || other_error || loader

                      //  ||
                      // (fristMobileNumberError && fristMobileNumberError?.already_exists)
                    }>
                     Submit {loader ? <div class="spinner-border spinner-border-sm" role="status">
                      </div> : null}
                    </button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </section >
      </div >
    </>
  )
}

export default BuyerRegistrationForm
