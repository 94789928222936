import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
const NewDataData = () => {
    // State management
    const [roomOccupancy, setRoomOccupancy] = useState("Single");
    const [addSecondPerson, setAddSecondPerson] = useState(false);
    const [ticketsBookedByIGI, setTicketsBookedByIGI] = useState(true);
    const [ticketsBookedByIGI2ndPerson, setTicketsBookedByIGI2ndPerson] = useState(true);
    // Handlers
    const handleRoomOccupancyChange = (e) => {
        const value = e.target.value;
        setRoomOccupancy(value);
        if (value === "Single") {
            setAddSecondPerson(false);
        }
    };
    const handleAddSecondPersonChange = (e) => {
        setAddSecondPerson(e.target.checked);
    };
    return (
        <div className="container mt-4">
            <form>
                <h2>Retailers Detail Form</h2>
                {/* Row 1 - Room Occupancy and Company */}
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="roomOccupancy" className="form-label">Room Occupancy:</label>
                        <select className="form-select" id="roomOccupancy" value={roomOccupancy} onChange={handleRoomOccupancyChange}>
                            <option value="Single">Single</option>
                            <option value="Double">Double</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="companyName" className="form-label">Name of the Company:</label>
                        <input type="text" className="form-control" id="companyName" placeholder="Company Name" required />
                    </div>
                </div>
                {/* First Person Details */}
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="personName" className="form-label">Name of the Person:</label>
                        <input type="text" className="form-control" id="personName" placeholder="Person Name" required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="personMobile" className="form-label">Mobile Number:</label>
                        <input type="text" className="form-control" id="personMobile" placeholder="Mobile Number" required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="emailAddress" className="form-label">Email Address:</label>
                        <input type="email" className="form-control" id="emailAddress" placeholder="Email Address" />
                    </div>
                </div>
                {/* Second Person Checkbox */}
                {roomOccupancy === "Double" && (
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="addSecondPerson" checked={addSecondPerson} onChange={handleAddSecondPersonChange} />
                        <label className="form-check-label" htmlFor="addSecondPerson">Add Second Person's Details</label>
                    </div>
                )}
                {/* Second Person Details */}
                {addSecondPerson && (
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <label htmlFor="secondPersonName" className="form-label">Name of the 2nd Person:</label>
                            <input type="text" className="form-control" id="secondPersonName" placeholder="2nd Person Name" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="secondPersonMobile" className="form-label">Mobile Number of 2nd Person:</label>
                            <input type="text" className="form-control" id="secondPersonMobile" placeholder="2nd Person Mobile" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="secondPersonEmail" className="form-label">Email Address of 2nd Person:</label>
                            <input type="email" className="form-control" id="secondPersonEmail" placeholder="2nd Person Email" />
                        </div>
                    </div>
                )}
                {/* Hotel Details */}
                <h3>Hotel Details</h3>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="hotelCheckIn" className="form-label">Hotel Check-In Date:</label>
                        <input type="date" className="form-control" id="hotelCheckIn" required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="hotelCheckOut" className="form-label">Hotel Check-Out Date:</label>
                        <input type="date" className="form-control" id="hotelCheckOut" required />
                    </div>
                </div>
                {/* Departure Details */}
                <h3>Departure Detail</h3>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="ticketsBookedBy" className="form-label">Tickets booked by:</label>
                        <select className="form-select" id="ticketsBookedBy" value={ticketsBookedByIGI ? "IGI" : "Other"} onChange={(e) => setTicketsBookedByIGI(e.target.value === "IGI")}>
                            <option value="IGI">IGI</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="departureDate" className="form-label">Date of Departure:</label>
                        <input type="date" className="form-control" id="departureDate" required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="departureCity" className="form-label">Departure City:</label>
                        <input type="text" className="form-control" id="departureCity" placeholder="Departure City" required />
                    </div>
                </div>
                {/* Return Details */}
                <h3>Returning Detail</h3>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="returnFlightDate" className="form-label">Return Flight Date:</label>
                        <input type="date" className="form-control" id="returnFlightDate" required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="returningCity" className="form-label">Returning City:</label>
                        <input type="text" className="form-control" id="returningCity" placeholder="Returning City" required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="returningAirport" className="form-label">Returning Airport:</label>
                        <input type="text" className="form-control" id="returningAirport" placeholder="Returning Airport" required />
                    </div>
                </div>
                {/* Submit Button */}
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};
export default NewDataData;