import React, { useEffect, useState } from 'react'
import Footer from '../superAdmin/footer/Footer'
import Select from "react-select";
import DatePickers from '../../common/DatePickers';
import { message } from "antd";
import axios from "axios";
import { useParams } from 'react-router-dom';
import moment from 'moment';
// Function to convert a date string to ISO 8601 format
const convertToISO = (dateStr) => {
    // Parse the date string to a Date object
    const date = new Date(dateStr);
    // Convert to ISO 8601 format with time set to 00:00:00
    return date.toISOString().split('T')[0] + 'T00:00:00';
};

// Function to check if a given date is in the specified range
const isDateInRange = (date) => {
    // Define the start and end dates in ISO format
    const startDate = new Date(getNextDayFormatted(process.env.REACT_APP_START_DATE));
    const endDate = new Date(process.env.REACT_APP_END_DATE);

    // Convert the given date to ISO format and create a Date object
    const givenDate = new Date(convertToISO(date));

    // Perform the date range check
    return givenDate >= startDate && givenDate <= endDate;
};

function getNextDayFormatted(date) {
    return moment(date).add(1, 'days').format('lll');
}
// Example usage
const inputDate = "Nov 14, 2024";
const isValid = isDateInRange(inputDate);

console.log(isValid); // Output: false (since Nov 14, 2024 is not in the range)


const IgiStaffRegistrationForm = () => {

    const designationArray = ["Designation",
        "Director",
        "Partner",
        "Proprietor",
        "Managing Director",
        "COO",
        "Owner",
        "Manager Sales",
        "M D",
        "Senior Manager", 'others'];
    const [representative_list, setRepresentative_list] = useState([
        "Akhil",
        "Kapil Ladiwal",
        "Nisha",
        "Arpan",
        "Arpan",
        "Venkat",
        "Aarpan",
        "Abhishek Jain",
        "Sanjay",
        "Mr.Venkatasayee",
        "Venkatasayee. B",
        "Tanmay Das",
        "Sonu Vats",
        "T Das",
        "Pradnya H",
        "Kapil Ji",
        "Shrey Kamdar",
        "Bhavin Z",
        "Mr. T. Das",
        "Venkatesh Iyer",
        "Kapil",
        "Karan",
        "Akil Aravind",
        "Venkatasayee Balasunderam",
        "Sonu",
        "Venkatasayee",
        "Nitin",
        "Arjun Mistry",
        "Mr.Venkat",
        "Umesh",
        "Meenakshi Jain",
        "Akhil Aravind",
        "Akhl"
    ])
    const initialFormData = {
        representative: '',
        companyName: '',
        roomNo: '',
        personName: '',
        mobileNumber: '',
        emailAddress: '',
        designation: '',
        country: '',
        alloted_car: '',
        roomOccupancy: '',
        ticketsDepartureBookedBy: 'IGI',
        ticketsReturnBookedBy: 'IGI',
        ticketsDepartureBookedBy2nd: 'IGI',
        ticketsReturnBookedBy2nd: 'IGI',
        travelMode: '',
        travelReturnMode: '',
        departurePNR: '',
        otherDesignation: '',
        departureCity: '',
        departureDate: '',
        flightTrainName: '',
        flightTrainNumber: '',
        arrivalTimeGoa: '',
        goaAirport: '',
        returnPNR: '',
        returnCity: '',
        returnFlightDate: '',
        returnFlightName: '',
        returnFlightNumber: '',
        returnFlightDepartureTime: '',
        returnAirport: '',
    };
    const [formData, setFormData] = useState(initialFormData);
    const [hotelCheckInDate, setHotelCheckInDate] = useState(null)
    const [hotelCheckOutDate, setHotelCheckOutDate] = useState(null)
    const [departureDate, setDepartureDate] = useState(null)
    const [returnFlightDate, setReturnFlightDate] = useState(null)
    const urlPath = window.location.pathname; // This will return '/buyer-registration-form'
    const extractedPart = urlPath.split('/').pop(); // This will return 'buyer-registration-form'
    const [travelModerarrivalArray, setTravelModerarrivalArray] = useState(["Train", "Flight", "Self"])
    const [travelModerDepartureArray, setTravelModerDepartureArray] = useState(["Train", "Flight", "Self"])

    // console.log(extractedPart);
    const handleSelectChange = (selectedOption, object) => {
        const selectedValue = selectedOption ? selectedOption.value : null;


        if (selectedValue !== "others") {
            setFormData({ ...formData, designation: selectedValue, otherDesignation: "" });
        } else {
            setFormData({ ...formData, designation: "others" });
        }

    };

    const handleOtherDesignationChange = (event, object) => {
        const value = event.target.value;
        if (object === "2nd") {
            setFormData({ ...formData, otherDesignation2nd: value });

        } else {

            setFormData({ ...formData, otherDesignation: value });
        }
    };
    // Create a mapping object for field names to display names
    const fieldDisplayNames = {
        representative: 'Representative',
        companyName: 'Company Name',
        roomNo: 'Room Number',
        roomOccupancy: 'Room Occupancy',
        personName: 'Person Name',
        emailAddress: 'Email Address',
        mobileNumber: 'Mobile Number',
        designation: 'Designation',
        hotelCheckInDate: 'Hotel Check-In Date',
        hotelCheckOutDate: 'Hotel Check-Out Date',
        ticketsBookedBy: 'Tickets Booked By',
        travelMode: 'Travel Mode',
        travelReturnMode: 'Return Travel Mode',

        departurePNR: 'Departure PNR',
        departureCity: 'Departure City',
        departureDate: 'Departure Date',
        flightTrainName: 'Flight/Train Name',
        flightTrainNumber: 'Flight/Train Number',
        arrivalTimeGoa: 'Arrival Time in Goa',
        goaAirport: 'Goa Airport',
        returnPNR: 'Return PNR',
        returnCity: 'Return City',
        returnFlightDate: 'Return Flight Date',
        returnFlightName: 'Return Flight Name',
        returnFlightNumber: 'Return Flight Number',
        returnFlightDepartureTime: 'Return Flight Departure Time',
        returnAirport: 'Return Airport of 2nd person',
    };

    const ticket_by = ["IGI", "Self"]
    const occupancy_list = ["Single", "Double"]
    const airportListOfGoa = ["Dabolim Airport and Manohar International Airport", "Mopa Airport"]


    const validateEmail = (email) => {
        // Simple email regex for validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'companyName') {
            // Capitalize the first letter of each word for companyName
            const uppercaseValue = value.toUpperCase();
            setFormData(prevData => ({ ...prevData, [name]: uppercaseValue }));
        } else if (['representative', 'companyName', 'personName', 'personName2nd'].includes(name)) {
            // Ensure only alphabetic characters for these fields
            const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, '');
            setFormData(prevData => ({ ...prevData, [name]: alphabeticValue }));
        }

        else if (name === 'mobileNumber') {
            // Ensure only numeric characters and length is 10
            const numericValue = value.replace(/[^0-9]/g, '');
            setFormData(prevData => ({
                ...prevData,
                [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
            }));
        } else if (name === 'mobileNumber2nd') {
            // Ensure only numeric characters and length is 10
            const numericValue = value.replace(/[^0-9]/g, '');
            setFormData(prevData => ({
                ...prevData,
                [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
            }));
        }

        else {
            // For other fields, just update the state as usual
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };


    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (value) {

            if (name === 'emailAddress' && !validateEmail(value)) {
                alert('This is not a correct email address');
                setFormData(prevData => ({ ...prevData, [name]: '' })); // Optionally clear the field
            }
            if (name === 'emailAddress2nd' && !validateEmail(value)) {
                alert('This is not a correct email address');
                setFormData(prevData => ({ ...prevData, ["emailAddress2nd"]: '' })); // Optionally clear the field
            }
        }
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Before sending to the API, ensure `designation` holds the correct value
        console.log("form Data", formData)
    };

    useEffect(() => {
        if (hotelCheckInDate) {
            console.log("hello", hotelCheckInDate, isDateInRange(hotelCheckInDate));
            if (!isDateInRange(hotelCheckInDate)) {
                message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
            }
        }
    }, [hotelCheckInDate]);

    useEffect(() => {
        if (hotelCheckOutDate) {
            console.log("hello", hotelCheckOutDate, isDateInRange(hotelCheckOutDate));
            if (!isDateInRange(hotelCheckOutDate)) {
                message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
            }
        }
    }, [hotelCheckOutDate]);


    useEffect(() => {
        if (departureDate) {
            console.log("hello", departureDate, isDateInRange(departureDate));
            if (!isDateInRange(departureDate)) {
                message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
            }
        }
    }, [departureDate]);

    useEffect(() => {
        if (returnFlightDate) {
            console.log("hello", returnFlightDate, isDateInRange(returnFlightDate));
            if (!isDateInRange(returnFlightDate)) {
                message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
            }
        }
    }, [returnFlightDate]);

    useEffect(() => {
        if (formData.ticketsDepartureBookedBy === "IGI") {

            setTravelModerarrivalArray(["Train", "Flight"])
        } else {
            setTravelModerarrivalArray(["Train", "Flight", "Self"])

        }
    }, [formData.ticketsDepartureBookedBy])

    useEffect(() => {
        if (formData.ticketsReturnBookedBy === "IGI") {

            setTravelModerDepartureArray(["Train", "Flight"])
        } else {

            setTravelModerDepartureArray(["Train", "Flight", "Self"])
        }
    }, [formData.ticketsReturnBookedBy])


    // 
    useEffect(() => {
        if (extractedPart === `gcc-retailer-registration-form`) {
            setRepresentative_list(['Sadiye'])

        }
    }, [])
    return (
        <>
            <div>
                <div className="text-center">
                    <h4 className="form-heading">IGI Staff Registration Form</h4>
                </div>

                <section>

                    <div className="container">
                        <div className="form-body-section">

                            <div className="form-body">
                                <form onSubmit={handleSubmit}>


                                    <div className="row">



                                        {/* <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                IGI Representative <font color="#FF0000">*</font>

                                            </label>
                                            <Select
                                                className="my-2"
                                                options={representative_list.map((data) => ({
                                                    value: data,
                                                    label: data,
                                                }))}
                                                value={
                                                    formData.representative
                                                        ? { value: formData.representative, label: formData.representative }
                                                        : null
                                                }
                                                onChange={(selectedOption) => setFormData({ ...formData, representative: selectedOption ? selectedOption.value : null })}


                                                isSearchable
                                                placeholder="Select Representative"
                                            />
                                        </div> */}
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Name of the Company <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                className="form-control my-2"
                                                placeholder="Company Name"
                                                value={formData.companyName}
                                                required
                                                name="companyName"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Room Occupancy
                                                {/* <font color="#FF0000">*</font> */}
                                            </label>
                                            <Select
                                                required

                                                className="my-2"
                                                options={occupancy_list.map((data) => ({
                                                    value: data,
                                                    label: data,
                                                }))}
                                                value={
                                                    formData.roomOccupancy
                                                        ? { value: formData.roomOccupancy, label: formData.roomOccupancy }
                                                        : null
                                                }
                                                onChange={(selectedOption) => setFormData({ ...formData, roomOccupancy: selectedOption ? selectedOption.value : null })}


                                                isSearchable
                                                placeholder="Select designation"
                                            />

                                        </div>
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Name of the Person <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                className="form-control my-2"
                                                placeholder="Person Name"
                                                value={formData.personName}

                                                required
                                                name="personName"
                                                onChange={handleChange}
                                            />
                                        </div>


                                        {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Room No
                        <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Room No"
                        value={formData.roomNo}

                        name="roomNo"
                        onChange={handleChange}
                      />
                    </div> */}

                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Mobile number <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                className="form-control my-2"
                                                placeholder="Mobile number"
                                                value={formData.mobileNumber}
                                                required
                                                name="mobileNumber"
                                                onChange={handleChange}
                                            />

                                        </div>
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Email Address
                                            </label>
                                            <input
                                                type="email"
                                                name="emailAddress"
                                                className="form-control my-2"
                                                value={formData.emailAddress}
                                                required
                                                onChange={handleChange}
                                                onBlur={handleBlur} // Add onBlur event handler
                                                placeholder="Email Address"
                                            />
                                            {/* <input
                        value={formData.emailAddress}

                        className="form-control my-2"
                        placeholder="Email Address"
                        name="emailAddress"
                        onChange={handleChange}
                      /> */}
                                        </div>

                                        {extractedPart === `gcc-retailer-registration-form` &&
                                            <div className="form-group col-lg-4 col-md-6">
                                                <label className="fw-bold">
                                                    Country <font color="#FF0000">*</font>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="country"
                                                    className="form-control my-2"
                                                    value={formData.country}
                                                    required
                                                    onChange={handleChange}
                                                    placeholder="Country"
                                                />
                                                {/* <input
                          value={formData.emailAddress}
  
                          className="form-control my-2"
                          placeholder="Email Address"
                          name="emailAddress"
                          onChange={handleChange}
                        /> */}
                                            </div>

                                        }

                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Designation <font color="#FF0000">*</font>
                                            </label>


                                            {/* Show the text field only if "others" is selected */}
                                            {formData.designation === "others" ? (
                                                <input
                                                    type="text"
                                                    className="form-control my-2"
                                                    required
                                                    placeholder="Enter your designation"
                                                    value={formData.otherDesignation}
                                                    onChange={(e) => handleOtherDesignationChange(e, '1st')}
                                                />
                                            ) :

                                                (
                                                    <Select
                                                        className="my-2"
                                                        options={designationArray.map((data) => ({
                                                            value: data,
                                                            label: data,
                                                        }))}
                                                        value={
                                                            formData.designation && formData.designation !== "others"
                                                                ? { value: formData.designation, label: formData.designation }
                                                                : null
                                                        }
                                                        onChange={(e) => handleSelectChange(e, '1st')}
                                                        required
                                                        isSearchable
                                                        placeholder="Select designation"
                                                    />
                                                )}
                                        </div>

                                        {formData.roomOccupancy === "Double" &&
                                            <div className="row">

                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Name of the 2nd Person <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        className="form-control my-2"
                                                        placeholder="Person Name"
                                                        value={formData.personName2nd}

                                                        name="personName2nd"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Mobile number of 2nd Person <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        className="form-control my-2"
                                                        placeholder="Mobile number"
                                                        value={formData.mobileNumber2nd}

                                                        name="mobileNumber2nd"
                                                        onChange={handleChange}
                                                    />

                                                </div>
                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Email Address  of 2nd Person
                                                    </label>
                                                    <input
                                                        type="email"
                                                        name="emailAddress2nd"
                                                        className="form-control my-2"
                                                        value={formData.emailAddress2nd}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} // Add onBlur event handler
                                                        placeholder="Email Address"
                                                    />
                                                    {/* <input
                             value={formData.emailAddress}
     
                             className="form-control my-2"
                             placeholder="Email Address"
                             name="emailAddress"
                             onChange={handleChange}
                           /> */}
                                                </div>

                                                {extractedPart === `gcc-retailer-registration-form` &&
                                                    <div className="form-group col-lg-4 col-md-6">
                                                        <label className="fw-bold">
                                                            Country  <font color="#FF0000">*</font>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="country"
                                                            className="form-control my-2"
                                                            value={formData.country}
                                                            onChange={handleChange}
                                                            placeholder="Country"
                                                        />
                                                        {/* <input
                               value={formData.emailAddress}
       
                               className="form-control my-2"
                               placeholder="Email Address"
                               name="emailAddress"
                               onChange={handleChange}
                             /> */}
                                                    </div>

                                                }

                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Designation  of 2nd Person<font color="#FF0000">*</font>
                                                    </label>


                                                    {/* Show the text field only if "others" is selected */}
                                                    {formData.designation === "others" ? (
                                                        <input
                                                            type="text"
                                                            className="form-control my-2"
                                                            placeholder="Enter your designation"
                                                            value={formData.otherDesignation2nd}
                                                            onChange={(e) => handleOtherDesignationChange(e, '2nd')}
                                                        />
                                                    ) :

                                                        (
                                                            <Select
                                                                className="my-2"
                                                                options={designationArray.map((data) => ({
                                                                    value: data,
                                                                    label: data,
                                                                }))}
                                                                value={
                                                                    formData.designation2nd && formData.designation2nd !== "others"
                                                                        ? { value: formData.designation2nd, label: formData.designation2nd }
                                                                        : null
                                                                }
                                                                onChange={(e) => handleSelectChange(e, '2nd')}
                                                                isSearchable
                                                                placeholder="Select designation"
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div className="text-center">
                                        <h4 className="form-heading">{"Hotel Detail"}</h4>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Hotel Check-In Date <font color="#FF0000">*</font>
                                            </label>
                                            <DatePickers
                                                setDate={setHotelCheckInDate} lable={'Hotel Check-In Date (2nd Person)'}
                                                required={true}

                                            />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Hotel Check-Out Date <font color="#FF0000">*</font>
                                            </label>
                                            <DatePickers
                                                setDate={setHotelCheckOutDate} lable={'Hotel Check-Out Date (2nd Person)'}
                                                required={true}
                                            />
                                        </div>

                                    </div>


                                    {/* <div className="text-center">
                    <h4 className="form-heading">{"Travel Detail"}</h4>
                  </div>
                  <hr /> */}

                                    <div className="text-center">
                                        <h4 className="form-heading">{"Departure Detail"}</h4>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Tickets booked by
                                                {/* <font color="#FF0000">*</font> */}
                                            </label>
                                            <Select
                                                className="my-2"
                                                options={ticket_by.map((data) => ({
                                                    value: data,
                                                    label: data,
                                                }))}
                                                value={
                                                    formData.ticketsDepartureBookedBy
                                                        ? { value: formData.ticketsDepartureBookedBy, label: formData.ticketsDepartureBookedBy }
                                                        : null
                                                }
                                                onChange={(selectedOption) => setFormData({ ...formData, ticketsDepartureBookedBy: selectedOption ? selectedOption.value : null })}
                                                required


                                                isSearchable
                                                placeholder="Select designation"
                                            />

                                        </div>
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Travel Mode <font color="#FF0000">*</font>
                                            </label>
                                            <Select
                                                className="my-2"
                                                options={travelModerarrivalArray.map((data) => ({
                                                    value: data,
                                                    label: data,
                                                }))}
                                                value={
                                                    formData.travelMode
                                                        ? { value: formData.travelMode, label: formData.travelMode }
                                                        : null
                                                }
                                                onChange={(selectedOption) => setFormData({ ...formData, travelMode: selectedOption ? selectedOption.value : null })}
                                                required


                                                isSearchable
                                                placeholder="Select designation"
                                            />

                                        </div>
                                        {extractedPart === `gcc-retailer-registration-form` &&
                                            <div className="form-group col-lg-4 col-md-6">
                                                <label className="fw-bold">
                                                    Alloted Car <font color="#FF0000">*</font>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="alloted_car"
                                                    className="form-control my-2"
                                                    value={formData.alloted_car}
                                                    onChange={handleChange}
                                                    required
                                                    onBlur={handleBlur} // Add onBlur event handler
                                                    placeholder="Alloted Car"
                                                />
                                                {/* <input
                          value={formData.emailAddress}
  
                          className="form-control my-2"
                          placeholder="Email Address"
                          name="emailAddress"
                          onChange={handleChange}
                        /> */}
                                            </div>

                                        }

                                        {formData.ticketsDepartureBookedBy === "Self" && formData.travelMode === "Self" ? null : formData.ticketsDepartureBookedBy === "Self" ? <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Departure PNR <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                className="form-control my-2"
                                                value={formData.departurePNR}
                                                required

                                                placeholder="Departure PNR"
                                                name="departurePNR"
                                                onChange={handleChange}
                                            />
                                        </div> : null}
                                        {formData.ticketsDepartureBookedBy === "Self" && formData.travelMode === "Self" ? null :

                                            <>

                                                <div className="form-group col-lg-4 col-md-6">

                                                    <label className="fw-bold">
                                                        Departure City <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        className="form-control my-2"
                                                        value={formData.departureCity}
                                                        required

                                                        placeholder="Departure City"
                                                        name="departureCity"
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Date of Departure <font color="#FF0000">*</font>
                                                    </label>
                                                    <DatePickers
                                                        setDate={setDepartureDate} lable={'Date of Departure'}
                                                        required={true}

                                                    />
                                                </div>


                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Flight / Train name <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        className="form-control my-2"
                                                        value={formData.flightTrainName}
                                                        required

                                                        placeholder="Flight / Train name"
                                                        name="flightTrainName"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Flight / Train Number <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        className="form-control my-2"
                                                        placeholder="Flight / Train Number"
                                                        value={formData.flightTrainNumber}
                                                        required

                                                        name="flightTrainNumber"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Arrival time at Goa <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        // type="text"
                                                        type="time"
                                                        className="form-control my-2"
                                                        required
                                                        placeholder="Arrival Time"
                                                        value={formData.arrivalTimeGoa}
                                                        name="arrivalTimeGoa"
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Goa Airport <font color="#FF0000">*</font>
                                                    </label>
                                                    {/* <input
                        className="form-control my-2"
                        value={formData.goaAirport}

                        placeholder="Goa Airport"
                        name="goaAirport"
                        onChange={handleChange}
                      /> */}
                                                    <Select
                                                        className="my-2"
                                                        options={airportListOfGoa.map((data) => ({
                                                            value: data,
                                                            label: data,
                                                        }))}
                                                        value={
                                                            formData.goaAirport
                                                                ? { value: formData.goaAirport, label: formData.goaAirport }
                                                                : null
                                                        }
                                                        onChange={(selectedOption) => setFormData({ ...formData, goaAirport: selectedOption ? selectedOption.value : null })}

                                                        required

                                                        isSearchable
                                                        placeholder="Select Goa Airport"
                                                    />

                                                </div>
                                            </>
                                        }


                                    </div>
                                    {formData.roomOccupancy === "Double" &&
                                        <div className="row">
                                            <div className="form-group col-lg-4 col-md-6">
                                                <label className="fw-bold">
                                                    Tickets booked by (2nd Person)
                                                    {/* <font color="#FF0000">*</font> */}
                                                </label>
                                                <Select
                                                    className="my-2"
                                                    options={ticket_by.map((data) => ({
                                                        value: data,
                                                        label: data,
                                                    }))}
                                                    required
                                                    value={
                                                        formData.ticketsDepartureBookedBy2nd
                                                            ? { value: formData.ticketsDepartureBookedBy2nd, label: formData.ticketsDepartureBookedBy2nd }
                                                            : null
                                                    }
                                                    onChange={(selectedOption) => setFormData({ ...formData, ticketsDepartureBookedBy2nd: selectedOption ? selectedOption.value : null })}


                                                    isSearchable
                                                    placeholder="Select designation"
                                                />

                                            </div>





                                        </div>}

                                    <div className="text-center">
                                        <h4 className="form-heading">{"Returning Detail"}</h4>
                                    </div>
                                    <hr />

                                    <div className="row">
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Tickets booked by
                                                {/* <font color="#FF0000">*</font> */}
                                            </label>
                                            <Select
                                                className="my-2"
                                                options={ticket_by.map((data) => ({
                                                    value: data,
                                                    label: data,
                                                }))}
                                                required
                                                value={
                                                    formData.ticketsReturnBookedBy
                                                        ? { value: formData.ticketsReturnBookedBy, label: formData.ticketsReturnBookedBy }
                                                        : null
                                                }
                                                onChange={(selectedOption) => setFormData({ ...formData, ticketsReturnBookedBy: selectedOption ? selectedOption.value : null })}


                                                isSearchable
                                                placeholder="Select designation"
                                            />

                                        </div>
                                        <div className="form-group col-lg-4 col-md-6">
                                            <label className="fw-bold">
                                                Travel Mode<font color="#FF0000">*</font>
                                            </label>
                                            <Select
                                                className="my-2"
                                                required
                                                options={travelModerDepartureArray.map((data) => ({
                                                    value: data,
                                                    label: data,
                                                }))}
                                                value={
                                                    formData.travelReturnMode
                                                        ? { value: formData.travelReturnMode, label: formData.travelReturnMode }
                                                        : null
                                                }
                                                onChange={(selectedOption) => setFormData({ ...formData, travelReturnMode: selectedOption ? selectedOption.value : null })}


                                                isSearchable
                                                placeholder="Select designation"
                                            />

                                        </div>
                                        {extractedPart === `gcc-retailer-registration-form` &&
                                            <div className="form-group col-lg-4 col-md-6">
                                                <label className="fw-bold">
                                                    Alloted Car (2nd Person)<font color="#FF0000">*</font>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="alloted_car"
                                                    className="form-control my-2"
                                                    value={formData.alloted_car}
                                                    onChange={handleChange}
                                                    required
                                                    onBlur={handleBlur} // Add onBlur event handler
                                                    placeholder="Alloted Car"
                                                />
                                                {/* <input
                          value={formData.emailAddress}
  
                          className="form-control my-2"
                          placeholder="Email Address"
                          name="emailAddress"
                          onChange={handleChange}
                        /> */}
                                            </div>

                                        }

                                        {formData.ticketsReturnBookedBy === "Self" && formData.travelReturnMode === "Self" ? null : formData.ticketsReturnBookedBy === "Self" ?
                                            <div className="form-group col-lg-4 col-md-6">
                                                <label className="fw-bold">
                                                    Returning PNR <font color="#FF0000">*</font>
                                                </label>
                                                <input
                                                    className="form-control my-2"
                                                    placeholder="Returning PNR"
                                                    value={formData.returnPNR}
                                                    required

                                                    name="returnPNR"
                                                    onChange={handleChange}
                                                />
                                            </div> : null}

                                        {formData.ticketsReturnBookedBy === "Self" && formData.travelReturnMode === "Self" ? null :
                                            <>

                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Returning City <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        className="form-control my-2"
                                                        value={formData.returnCity}
                                                        required

                                                        placeholder="Returning City"
                                                        name="returnCity"
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Return Flight Date <font color="#FF0000">*</font>
                                                    </label>
                                                    <DatePickers
                                                        setDate={setReturnFlightDate} lable={'Date of Departure'}
                                                        required={true}
                                                    />
                                                </div>


                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Flight Name <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        className="form-control my-2"
                                                        value={formData.returnFlightName}
                                                        required

                                                        placeholder="Flight Name"
                                                        name="returnFlightName"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Flight Number <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        className="form-control my-2"
                                                        value={formData.returnFlightNumber}
                                                        required

                                                        placeholder="Flight Number"
                                                        name="returnFlightNumber"
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Flight Departure Time <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        // type="text"
                                                        type="time"
                                                        className="form-control my-2"
                                                        placeholder="Arrival Time"
                                                        required
                                                        value={formData.returnFlightDepartureTime}
                                                        name="returnFlightDepartureTime"
                                                        onChange={handleChange}
                                                    />
                                                </div>



                                                <div className="form-group col-lg-4 col-md-6">
                                                    <label className="fw-bold">
                                                        Returning Airport <font color="#FF0000">*</font>
                                                    </label>
                                                    <input
                                                        className="form-control my-2"
                                                        placeholder="Returning Airport"
                                                        required
                                                        value={formData.returnAirport}
                                                        name="returnAirport"
                                                        onChange={handleChange}
                                                    />
                                                </div></>}

                                    </div>
                                    {formData.roomOccupancy === "Double" &&
                                        <div className="row">
                                            <div className="form-group col-lg-4 col-md-6">
                                                <label className="fw-bold">
                                                    Tickets booked by (2nd Person)
                                                    {/* <font color="#FF0000">*</font> */}
                                                </label>
                                                <Select
                                                    className="my-2"
                                                    options={ticket_by.map((data) => ({
                                                        value: data,
                                                        label: data,
                                                    }))}
                                                    required
                                                    value={
                                                        formData.ticketsReturnBookedBy2nd
                                                            ? { value: formData.ticketsReturnBookedBy2nd, label: formData.ticketsReturnBookedBy2nd }
                                                            : null
                                                    }
                                                    onChange={(selectedOption) => setFormData({ ...formData, ticketsReturnBookedBy2nd: selectedOption ? selectedOption.value : null })}


                                                    isSearchable
                                                    placeholder="Select designation"
                                                />

                                            </div>

                                        </div>



                                    }
                                    <div className="text-center my-4">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default IgiStaffRegistrationForm
