import React from "react";
import Container from "react-bootstrap/Container";
import Logo from "../../assets/image/logo.png";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import CommonHeader from "../../common/CommonHeader";

function SubAdminNavbar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("jff_token");
    localStorage.removeItem("category");
    localStorage.removeItem("permission");
    navigate("/adminvip/login");
  };
  return (
    <>
      <Navbar
        bg="dark"
        expand="lg"
        data-bs-theme="dark"
        className="bg-body-tertiary"
      >

<CommonHeader redirection={"/adminvip/exhibitor-list"}/>

        <Container>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              // style={{ maxHeight: "100px" }}
              navbarScroll
            >
              {" "}
              <Nav.Link onClick={() => navigate("/adminvip/exhibitor-list")}>
                Invited Buyer List
              </Nav.Link>
              <Nav.Link onClick={() => navigate("/adminvip/change-password")}>
                Change Password
              </Nav.Link>
              <Nav.Link onClick={() => navigate("/hospitality/hotel-detail")}>
                Hotel
              </Nav.Link>
              <Nav.Link onClick={() => navigate("/cab-service/cab-detail")}>
                Cab Service
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/flight-service/flight-details")}
              >
                Flight Service
              </Nav.Link>
              <Nav.Link onClick={() => handleLogout()}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default SubAdminNavbar;
