import React from 'react';

const Tandcform = () => {
  return (
    <div className="networking-meet-form">
      <h4>Guidelines for Attending the PMI Networking Meet</h4>
      <ul className="text-danger small" >
        <li>
          You are expected to meet each participating manufacturer as per the
          schedule provided for the event. Based on data and feedback from each
          participant, your rating goes up and so does your popularity.
        </li>
        <li>
          You shall not indulge in the conduct of any personal business
          during the show timing, i.e., between 10:30 am – 7:00 pm.
        </li>
        <li> Always maintain decorum and correct attire.</li>
        <li>
          Request you to attend the Networking Nite post the Meet. It’s the
          perfect opportunity to mix business and pleasure.
        </li>
        <li>
          By confirming your presence for this event, you have accepted to
          adhere to the rules and regulations of the event.
        </li>
        <li>

          Retailers are allowed to make changes within 72 hours of submission of the form. For any modifications, please contact your GJC Sales representative, who will make the necessary updates on your behalf.
        </li>
      </ul>

      <p className="disclaimer text-danger small">
        Disclaimer: GJC will be sending you communications through SMS, Emailer
        etc. pertaining to its activities/industry updates. Through this form,
        you hereby confirm you have no objection to the said activity.
      </p>

    </div>
  );
};

export default Tandcform;
