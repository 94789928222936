import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Header from "../dashboard/Header";
import Footer from "../footer/Footer";
import { BiArrowBack } from "react-icons/bi";
import { message } from "antd";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import LoginRedirection from "../../Service/LoginRedirection";
import TokenUndefined from "../../Service/TokenUndefined";
import { Spinner } from "react-bootstrap";

const AddArea = ({
  setEditArea,
  editArea,
  company_Name,
  // setCompanyName,
  contact_Person,
  // setContactPerson,
  mobile_No,
  // setMobileNo,
  email_s,
  // setEmail,
  stall_Size,
  // setStallSize,
  stall_No,
  // setStallNo,
  editId,
  areaId,
  barCode,
}) => {
  const [array, setArray] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [stallSize, setStallSize] = useState("");
  const [stallSizeName, setStallSizeName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [stallNo, setStallNo] = useState("");
  const [exhibitorLogo, setExhibitorLogo] = useState("");
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorNumber, setErrorNumber] = useState("");

  const navigate = useNavigate();
  const [area_id, setArea_id] = useState("");

  const handleContactPersonChange = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setContactPerson(alphabetOnlyValue);
  };

  const handleMobileNoChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setMobileNo(numericValue);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!emailPattern.test(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    TokenUndefined("superadmin/login");
    if (contact_Person !== undefined) {
      setContactPerson(contact_Person);
    }
    if (mobile_No !== undefined) {
      setMobileNo(mobile_No);
    }
    if (email_s !== undefined) {
      setEmail(email_s);
    }
    if (company_Name !== undefined) {
      setCompanyName(company_Name);
    }
    if (stall_Size !== undefined) {
      setStallSizeName(stall_Size);
      console.log("sadsadssadsaddasdassaddasadsadssad", stall_Size);
    }
    if (stall_No !== undefined) {
      setStallNo(stall_No);
    }
    if (areaId !== undefined) {
      setArea_id(areaId);
    }
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/get-area-all-list`, {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
      })
      .then((res) => {
        LoginRedirection(res, "superadmin/login");
        setArray(res.data.data);
      });
  }, [reload]);

  const handleSubmit = () => {
    TokenUndefined("superadmin/login");
    setIsLoading(true);

    if (!companyName) {
      message.error("Comapny Name field is Empty");
      setIsLoading(false);
      return;
    }
    if (!contactPerson) {
      message.error("Contact Person field is Empty ");
      setIsLoading(false);
      return;
    }
    if (!mobileNo) {
      message.error("Mobile No. field is Empty");
      setIsLoading(false);
      return;
    }
    if (mobileNo.length !== 10) {
      message.error("Please Enter 10 Digit Mobile Number");
      setIsLoading(false);
      return;
    }
  
    if (!stallSizeName) {
      message.error("Stall Size field is Empty");
      setIsLoading(false);
      return;
    }

    if (editArea) {
      if (!exhibitorLogo) {
        axios
          .post(
            `${process.env.REACT_APP_API_END_POINT}/update-exhibitor`,
            {
              company_name: companyName,
              contact_person: contactPerson,
              mobile_no: mobileNo,
              email,
              stall_size: stallSizeName,
              stall_no: stallNo,
              id: editId,
              area_id,
            },
            {
              headers: {
                token: localStorage.getItem("jff_token"),
                // barcode: barCode,
              },
            }
          )
          .then((res) => {
            LoginRedirection(res, "superadmin/login");
            setIsLoading(false);
            message.success("Success");
            window.location.reload(true);
            navigate("/superadmin/exhibitor-list");
          });
      } else {
        const formData = new FormData();
        formData.append("company_name", companyName);
        formData.append("contact_person", contactPerson);
        formData.append("mobile_no", mobileNo);
        // formData.append("email", email);
        formData.append("stall_size", stallSizeName);
        formData.append("stall_no", stallNo);
        formData.append("id", editId);
        // formData.append("barcode", barCode)
        formData.append("file", exhibitorLogo);
        formData.append("area_id", area_id);
        axios
          .post(
            `${process.env.REACT_APP_API_END_POINT}/update-exhibitor`,
            formData,
            {
              headers: {
                token: localStorage.getItem("jff_token"),
                barcode: barCode,
              },
            }
          )
          .then((res) => {
            LoginRedirection(res, "superadmin/login");
            setIsLoading(false);
            message.success("Success");
            window.location.reload(true);
            navigate("/superadmin/exhibitor-list");
          });
      }
    } else {
      if (!exhibitorLogo) {
        axios
          .post(
            `${process.env.REACT_APP_API_END_POINT}/add-exhibitor`,
            {
              company_name: companyName,
              contact_person: contactPerson,
              mobile_no: mobileNo,
              // email,
              stall_size: stallSizeName,
              stall_no: stallNo,
              area_id,
            },
            {
              headers: {
                token: localStorage.getItem("jff_token"),
              },
            }
          )
          .then((res) => {
            LoginRedirection(res, "superadmin/login");
            setIsLoading(false);
            message.success("Form Submit Successfully");
            setReload(!reload);
            navigate("/superadmin/exhibitor-list");
          });
      } else {
        const formData = new FormData();
        formData.append("company_name", companyName);
        formData.append("contact_person", contactPerson);
        formData.append("mobile_no", mobileNo);
        // formData.append("email", email);
        formData.append("stall_size", stallSizeName);
        formData.append("stall_no", stallNo);
        formData.append("file", exhibitorLogo);
        formData.append("area_id", area_id);
        axios
          .post(
            `${process.env.REACT_APP_API_END_POINT}/add-exhibitor`,
            formData,
            {
              headers: {
                token: localStorage.getItem("jff_token"),
              },
            }
          )
          .then((res) => {
            LoginRedirection(res, "superadmin/login");
            setIsLoading(false);
            navigate("/superadmin/exhibitor-list");
            message.success("Form Submit Successfully");
          });
      }
    }
    if (setEditArea !== undefined) {
      setEditArea(false);
    }
  };
  return (
    <>
      <Header />
      <div className="container my-2">
        {editArea && (
          <div className="d-flex justify-content-end m-2">
            <Button
              title={`Go Back`}
              size={35}
              className="bg-dark border-dark"
              style={{ cursor: "pointer" }}
              onClick={() => setEditArea(false)}
            >
              Back
            </Button>
          </div>
        )}
        {editArea ? (
          <div className="text-center">
            <h3 className="form-heading">Edit Exhibitor</h3>
          </div>
        ) : (
          <div className="text-center">
            <h3 className="form-heading">Add Exhibitor</h3>
          </div>
        )}

        {/* <section> */}
        <div className="form-body-section">
          <div className="form-body">
            <div className="row">
              <div className="form-group col-lg-6 col-md-6">
                <label className="fw-bold">
                  Company Name <font color="#FF0000">*</font>
                </label>
                <input
                  className="form-control my-2"
                  type="text"
                  required
                  placeholder="Company Name"
                  name="area"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  autocomplete="off"
                />
              </div>
              <div className="form-group col-lg-6 col-md-6">
                <label className="fw-bold">
                  Contact Person <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  className="form-control my-2"
                  required
                  value={contactPerson}
                  placeholder="Contact Person "
                  name="owner"
                  onChange={(e) => handleContactPersonChange(e)}
                  autocomplete="off"
                  maxLength={30}
                />
              </div>
              <div className="form-group col-lg-6 col-md-6">
                <label className="fw-bold">
                  Mobile No. <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  className="form-control my-2"
                  required
                  placeholder="Mobile No."
                  name="staff"
                  value={mobileNo}
                  maxLength={10}
                  onChange={handleMobileNoChange}
                  autocomplete="off"
                />
                <labe className="text-danger">{errorNumber}</labe>
              </div>
              {/* <div className="form-group col-lg-6 col-md-6">
                <label className="fw-bold">
                  Email <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  className="form-control my-2"
                  required
                  value={email}
                  placeholder="Email"
                  name="vip_day_1"
                  onChange={(e) => handleEmailChange(e)}
                  autocomplete="off"
                />
              </div> */}
              <div className="form-group col-lg-6 col-md-6">
                <label className="fw-bold">
                  Stall Size <font color="#FF0000">*</font>
                </label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="form-control border my-2"
                  >
                    {stallSizeName ? stallSizeName : "Add Area"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="form-control nodesure"
                    style={{ overflowY: "scroll", maxHeight: "15vh" }}
                  >
                    {array?.map((item, i) => (
                      <Dropdown.Item
                        key={i}
                        value={item.area}
                        onClick={() => {
                          setArea_id(item._id);
                          setStallSizeName(item.area);
                        }}
                        className="d-flex justify-content-center"
                      >
                        {item.area}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="form-group col-lg-6 col-md-6">
                <label className="fw-bold">Stall No.</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  placeholder="Stall No."
                  name="vip_day_2"
                  value={stallNo}
                  onChange={(e) => setStallNo(e.target.value)}
                  autocomplete="off"
                />
              </div>
              <div className="form-group col-lg-6 col-md-6">
                <label className="fw-bold">Exhibitor Logo</label>
                <input
                  type="file"
                  className="form-control my-2"
                  required
                  placeholder="Stall No."
                  name="vip_day_2"
                  onChange={(e) => {
                    setExhibitorLogo(e.target.files[0]);
                  }}
                  autocomplete="off"
                  accept=".jpg, .jpeg, .png"
                />
              </div>
              <div className="form-submit">
                <Button
                  variant="btn btn-outline-secondary"
                  type="submit"
                  style={{ width: "90px" }}
                  value={"Submit"}
                  name="add"
                  className="save"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {" "}
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddArea;