import React from "react";
import CommonLogin from "../../../common/CommonLogin"
const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function SuperAdminAreaLogin() {

   const pathname = window.location.pathname;
   const userType = pathname.split("/")[1];
   localStorage.setItem(
     "exhibitor_agent_type",
     capitalizeFirstLetter(userType)
   );

 const exhibitorAgentType = localStorage.getItem("exhibitor_agent_type");



  return (
    <>
      <CommonLogin
        header={`Welcome to ${exhibitorAgentType.toLowerCase()} Area`}
        afterLogin={`/${exhibitorAgentType.toLowerCase()}/dashboard`}
        loginFailed={"/exhibitor/login"}
        prem_name={"exhibitor"}
      />
    </>
  );
}

export default SuperAdminAreaLogin;
