import * as React from "react";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import moment from "moment";
const minDate = new Date(process.env.REACT_APP_START_DATE).toISOString().split("T")[0];
const maxDate = new Date(process.env.REACT_APP_END_DATE).toISOString().split("T")[0];
export default function DatePickers({ selectedDate, setSelectedDate, lable, required }) {

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  return (
    // <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <DemoContainer components={["DatePicker"]}>
    //     <DatePicker
    //       // label={lable}

    //       onChange={handleDateChange}
    //     />
    //   </DemoContainer>
    // </LocalizationProvider>

    <div>
      <input
        style={{
          width: ' 100%',
          padding: ' 8px 7px',
        }
        }
        type="date"
        min={minDate}
        max={maxDate}
        required={required}
        id="date-picker"
        value={selectedDate}
        onChange={handleDateChange}
      />
    </div>
  );
}
