

import React from "react";
import CommonLogin from '../../common/CommonLogin'

function AdminVIPLogin() {


  return (
    <>
      <CommonLogin
        header={"Welcome to Admin Area"}
        afterLogin={"/adminvip/exhibitor-list"}
        loginFailed={"/adminvip/login"}
        prem_name={"vip_admin"}
      />
    </>
  );
}

export default AdminVIPLogin;










// import React, { useEffect, useState } from "react";
// import Logo from "../../assets/image/logo-image.png";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import { message } from "antd";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { Spinner } from "react-bootstrap";

// function AdminVIPLogin() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   const navigate = useNavigate();

//   const handleKeyDown = (event) => {
//     if (event.key === "Enter") {
//       handleChange();
//     }
//   };

//   useEffect(() => {
//     if (localStorage.getItem("jff_token")) {
//       navigate(`/adminvip/exhibitor-list`);
//     }
//   }, []);
//   const handleChange = () => {
//     setIsLoading(true);
//     if (!email) {
//       message.error("Enter username or email");
//       setIsLoading(false);

//       return;
//     }
//     if (!password) {
//       message.error("Enter password");
//       setIsLoading(false);

//       return;
//     }

//     axios
//       .post(`${process.env.REACT_APP_API_END_POINT}/login`, { email, password })
//       .then((response) => {
//         localStorage.setItem("jff_token", response.data.token);
//         localStorage.setItem("user_id", response.data.category._id);
//         localStorage.setItem(
//           "permission",
//           JSON.stringify(response.data.category.permissions)
//         );
//         localStorage.setItem("user_id", response.data.category._id);
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         setIsLoading(false);
//         console.log(error);
//       })
//       .finally(async () => {
//         let data = localStorage.getItem("permission");
//         data = JSON.parse(data);
//         console.log("data", data);
//         let nameArray = [];

//         axios
//           .post(`${process.env.REACT_APP_API_END_POINT}/get-permission-name`, {
//             permission: data,
//           })
//           .then((res) => {
//             console.log(".data.data", res.data.data);
//             nameArray = res.data.data;

//             if (nameArray.includes("vip_admin")) {
//               console.log("running inside if");
//               navigate("/adminvip/exhibitor-list");
//             } else {
//               message.error("Access Denied");
//             }
//           });
//       });
//   };

//   return (
//     <>
//       <div className="container">
//         <img
//           src={Logo}
//           alt="logo-img"
//           className="my-1 mx-auto d-flex"
//           style={{
//             height: "130px",
//             width: "230px",
//             marginTop: "40px !important",
//           }}
//         />

//         <div className="exhibitorlogin">
//           <div className="exhibitorloginbody">
//             <div className="fw-semi-bold fs-3">Welcome to Sub Admin Area</div>
//             <Form className="my-4">
//               <Form.Control
//                 type="username"
//                 placeholder="Username"
//                 className="my-4"
//                 required
//                 onChange={(e) => setEmail(e.target.value)}
//                 onKeyDown={handleKeyDown}
//               />

//               <Form.Control
//                 type="password"
//                 placeholder="Password"
//                 required
//                 onChange={(e) => setPassword(e.target.value)}
//                 onKeyDown={handleKeyDown}
//               />

//               <Button className="my-4 btn-block" onClick={handleChange}>
//                 {isLoading ? <Spinner animation="border" size="sm" /> : "Login"}
//               </Button>
//             </Form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default AdminVIPLogin;
