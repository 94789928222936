import React from "react";
import CommonLogin from '../../common/CommonLogin'

function HospitalityLogin() {


  return (
    <>
      <CommonLogin
        header={"Welcome to Flight Area"}
        afterLogin={"/hospitality/hotel-detail"}
        loginFailed={"/hospitality/login"}
        prem_name={"hospitality"}
      />
    </>
  );
}

export default HospitalityLogin;
























// import React, { useState, useEffect } from "react";
// import Logo from "../../assets/image/logo-image.png";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import { message } from "antd";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { Spinner } from "react-bootstrap";

// function HospitalityLogin() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   const navigate = useNavigate();

//   useEffect(() => {
//     if (localStorage.getItem("jff_token")) {
//       navigate(`/hospitality/hotel-detail`);
//     }
//   }, []);

//   const handleKeyDown = (event) => {
//     if (event.key === "Enter") {
//       handleChange();
//     }
//   };
//   const handleChange = () => {
//     setIsLoading(true);
//     if (!email) {
//       message.error("Enter username or email");
//       setIsLoading(false);

//       return;
//     }
//     if (!password) {
//       message.error("Enter password");
//       setIsLoading(false);

//       return;
//     } else {
//       axios
//         .post(`${process.env.REACT_APP_API_END_POINT}/login`, {
//           email,
//           password,
//         })
//         .then((response) => {
//           localStorage.setItem(
//             "permission",
//             JSON.stringify(response.data.category.permissions)
//           );
//           localStorage.setItem("jff_token", response.data.token);
//           localStorage.setItem("user_id", response.data.category._id);
//           setIsLoading(false);
//         })
//         .catch((error) => {
//           setIsLoading(false);
//           console.log(error);
//         })
//         .finally(() => {
//           let data = localStorage.getItem("permission");
//           data = JSON.parse(data);
//           console.log("data", data);
//           let nameArray = [];

//           // console.log("ssdsssasdasa",data[i])
//           axios
//             .post(
//               `${process.env.REACT_APP_API_END_POINT}/get-permission-name`,
//               {
//                 permission: data,
//               }
//             )
//             .then((res) => {
//               console.log(".data.data", res.data.data);
//               nameArray = res.data.data;

//               if (nameArray.includes("hospitality")) {
//                 console.log("running inside if");
//                 navigate("/hospitality/hotel-detail");
//               } else {
//                 message.error("Not Authorised");
//               }
//             });
//         });
//     }
//   };

//   return (
//     <>
//       <div className="container">
//         <img
//           src={Logo}
//           alt="logo-img"
//           className="my-1 mx-auto d-flex"
//           style={{
//             height: "130px",
//             width: "230px",
//             marginTop: "40px !important",
//           }}
//         />

//         <div className="exhibitorlogin">
//           <div className="exhibitorloginbody">
//             <div className="fw-semi-bold fs-3">Welcome to Hospitality</div>
//             <Form className="my-4">
//               <Form.Control
//                 type="username"
//                 id="inputUsename"
//                 aria-describedby="passwordHelpBlock"
//                 placeholder="Username"
//                 className="my-4"
//                 required
//                 onChange={(e) => setEmail(e.target.value)}
//                 onKeyDown={handleKeyDown}
//               />

//               <Form.Control
//                 type="password"
//                 id="inputPassword5"
//                 aria-describedby="passwordHelpBlock"
//                 placeholder="Password"
//                 required
//                 onChange={(e) => setPassword(e.target.value)}
//                 onKeyDown={handleKeyDown}
//               />

//               <Button className="my-4 btn-block" onClick={handleChange}>
//                 {isLoading ? <Spinner animation="border" size="sm" /> : "Login"}
//               </Button>
//             </Form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default HospitalityLogin;
