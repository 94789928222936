import React, { useEffect, useState } from "react";
import { MdOutlineFlight } from "react-icons/md";
import { AiOutlineFileImage } from "react-icons/ai";
import SubAdminNavbar from "./SubAdminNavbar";
import axios from "axios";
import { BsFillEyeFill, BsPersonFill } from "react-icons/bs";

import "jspdf-autotable";
import Footer from "../superAdmin/footer/Footer";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { Button, Modal, Spinner } from "react-bootstrap";
import Pagination from "../../pagination/Pagination";
import LoginRedirection from "../Service/LoginRedirection";
import TokenUndefined from "../Service/TokenUndefined";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../../common/DateTimeFormate";
import AllDetailOfPerson from "./AllDetailOfPerson";
import Select from 'react-select'
import { BiEdit } from "react-icons/bi";
import { notification } from "antd";
import DatePickers from "../../common/DatePickers";
// Function to convert a date string to ISO 8601 format

function getNextDayFormatted(date) {
  return moment(date).add(1, 'days').format('lll');
}
const convertToISO = (dateStr) => {
  // Parse the date string to a Date object
  const date = new Date(dateStr);
  // Convert to ISO 8601 format with time set to 00:00:00
  return date.toISOString().split('T')[0] + 'T00:00:00';
};

// Function to check if a given date is in the specified range
const isDateInRange = (date) => {
  // Define the start and end dates in ISO format
  const startDate = new Date(getNextDayFormatted(process.env.REACT_APP_START_DATE));
  const endDate = new Date(process.env.REACT_APP_END_DATE);

  // Convert the given date to ISO format and create a Date object
  const givenDate = new Date(convertToISO(date));

  // Perform the date range check
  return givenDate >= startDate && givenDate <= endDate;
};
const AdminInvitedVIPGuestList = () => {

  const initialFormData = {
    travelMode: '',
    departureCity: '',
    flightTrainName: '',
    flightTrainNumber: '',
    arrivalTimeGoa: '',
    departure_time_from_source: '',
    travelReturnMode: '',
    returnCity: '',
    returnFlightName: '',
    returnFlightNumber: '',
    returnFlightDepartureTime: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [vipGuestList, setVipGuestList] = useState([]);
  const [editArea, setEditArea] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalName, setModalName] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [room_No, setRoom_No] = useState("");
  const [room_allocation_time, setRoom_allocation_time] = useState("");
  const [hotel_allocation_time, setHotel_allocation_time] = useState("");
  const [hotel_name, setHotel_name] = useState("");
  const [cab_detail, setcab_detail] = useState([]);
  const [hotelDetail, sethotelDetail] = useState({});
  const [flight_details, setflight_details] = useState({});
  const [reload, setReload] = useState(false);
  const [totalLength, setTotalLength] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [dummyPage, setDummyPage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageValue, setImageValue] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [loadingState, setloadingState] = useState(false);
  const [remarks, setRemarks] = useState(false);
  const [remarksValue, setRemarksValue] = useState("");
  const [editID, setEditID] = useState();
  const [showAllDetails, setShowAllDetails] = useState(false)
  const [dataShowDetail, setDataShowDetail] = useState(null)
  const [visitorName, setVisitorName] = useState();
  const [barcode, setBarcode] = useState("");
  const [departureDate, setDepartureDate] = useState(null)
  const [returnFlightDate, setReturnFlightDate] = useState(null)
  const travelModerarrivalArray = ["Train", "Flight", "Self"]

  const [selected_category, setSelected_category] = useState({ value: 'exhibitor_owner', label: 'Exhibitor' })
  const [loader, setLoader] = useState(false)
  const dateValidation = `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`
  const [error_1st, set_error_1st] = useState(false);
  const [error_3rd, set_error_3rd] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleBack = () => {
    setEditArea(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true)

    // if ((!isDateInRange(departureDate))) {
    //   notification.error({ message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} Arrival date`, description: '' });

    //   setLoader(false)

    // }
    // else if (!isDateInRange(returnFlightDate)) {
    //   notification.error({ message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} Arrival date`, description: '' });

    //   setLoader(false)

    // } else {

    console.log("hello i am here", formData, departureDate, returnFlightDate);
    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/update-visitor-travel-preference`,
        { formData, departureDate, returnFlightDate, barcode },
        {
          headers: {
            token: `${localStorage.getItem("jff_token")}`,
          },
        }
      )
      .then((res) => {
        setLoader(false)
        setIsLoading(false);
        handleBack()
        setReload(!reload)
        notification.success({
          message: res?.data?.message,
          description: ''
        })
      })
      .catch((error) => {
        setLoader(false)
        notification.success({
          message: "Some Error/Contact To It Support",
          description: ''
        })

        console.error("Axios Error:", error);
      });
    // }

  };
  useEffect(() => {
    TokenUndefined("adminvip/login");
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/invited-vip-guest-list`, {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
        params: {
          page: dummyPage,
          category: selected_category.value,
          limit: limit,
          query: searchQuery,
        },
      })
      .then((res) => {
        LoginRedirection(res, "adminvip/login");
        setVipGuestList(res.data.data);
        setloadingState(true);

        // pagination
        setTotalLength(res.data.length);
        setLimit(res.data.limit);
        setPage(res.data.pageNo);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/adminvip/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  }, [reload, dummyPage, searchQuery, selected_category]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setDummyPage(1);
  };

  const handleRemarks = () => {
    TokenUndefined("superadmin/login");

    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/update-remarks-value`,
        {
          id: editID,
          remarks_value: remarksValue,
        },
        {
          headers: {
            token: localStorage.getItem("jff_token"),
          },
        }
      )
      .then((res) => {
        console.log("drtghjokploigyujok", res.data);
        LoginRedirection(res, "superadmin/login");
        setRemarksValue("");
        setRemarks(false);
        setReload(!reload);
      })
      .catch((err) => {
        // LoginRedirection("/superadmin/login");
        if (err.response.status === 401) {
          navigate("/superadmin/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  };

  const downloadAdminVipRequestList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_END_POINT
        }/download-admin-vip-request-data/${localStorage.getItem("user_id")}/${selected_category.value}`
      )
      .then((res) => {
        console.log("data is exported success Fully");
        const link = `${process.env.REACT_APP_API_END_POINT
          }/download-admin-vip-request-data/${localStorage.getItem("user_id")}/${selected_category.value}`;
        window.location.href = link;
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'companyName') {
      // Capitalize the first letter of each word for companyName
      const uppercaseValue = value.toUpperCase();
      setFormData(prevData => ({ ...prevData, [name]: uppercaseValue }));
    } else if (['representative', 'companyName', 'personName', 'personName2nd'].includes(name)) {
      // Ensure only alphabetic characters for these fields
      const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, '');
      setFormData(prevData => ({ ...prevData, [name]: alphabeticValue }));
    }

    else if (name === 'mobileNumber') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    } else if (name === 'mobileNumber2nd') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    }

    else {
      // For other fields, just update the state as usual
      setFormData(prevData => ({ ...prevData, [name]: value }));
    }
  };

  const handleEdit = (data) => {
    console.log("data.createdAt", data.createdAt)
    const createdAt = new Date(data.createdAt); // Replace with your database value
    const currentTime = new Date();

    // Calculate the difference in minutes
    const timeDifference = (currentTime - createdAt) / 60000;

    if (timeDifference < 5760) {
      notification.success({ message: `You can edit the following user`, description: '' });

      // Show edit UI or options
    } else {
      return notification.error({ message: `You can't edit the following user`, description: '' });

      // Disable edit options
    }

    setEditID(data._id);
    setVisitorName(data?.visitor_name);
    // setNumber(data?.mobile_number);
    // setPhoto(data?.photo);
    // setIDProofFront(data?.id_proof_front);
    // setIDProofBack(data?.id_proof_back);
    // setEmail(data?.email);
    // setCity(data?.city);
    // setCompanyName(data?.company_name);
    // setCountry(data?.country);
    // setPostalCode(data?.postal_code);
    // setStreetAddress(data?.street_address);
    // setState(data?.state);
    // setDesignation(data?.designation);
    // setVisiting_as(data?.visiting_as);
    setEditArea(true);
    setBarcode(data?.barcode);
    setFormData({
      travelMode: data?.travelMode,
      departureCity: data?.departureCity,
      flightTrainName: data?.flightTrainName,
      flightTrainNumber: data?.flightTrainNumber,
      arrivalTimeGoa: data?.arrivalTimeGoa,
      departure_time_from_source: data?.departure_time_from_source,
      travelReturnMode: data?.travelReturnMode,
      returnCity: data?.returnCity,
      returnFlightName: data?.returnFlightName,
      returnFlightNumber: data?.returnFlightNumber,
      returnFlightDepartureTime: data?.returnFlightDepartureTime,
    })
    setDepartureDate(data?.departureDate)
    setReturnFlightDate(data?.returnFlightDate)

  };
  return (
    <>

      <AllDetailOfPerson showAllDetails={showAllDetails} setShowAllDetails={setShowAllDetails} data={dataShowDetail} travel_data={true} />
      {editArea && (
        <>
          <div className="container">
            <div className="text-center">
              <h3 className="form-heading">Edit Travel Detail For {visitorName}</h3>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="bg-dark border-dark"
                onClick={() => handleBack()}
              >
                Back
              </Button>
            </div>
            <form onSubmit={handleSubmit}>
              <>
                <div className="text-center ">
                  <h4 className="form-heading">{"Arrival Detail"}</h4>
                </div>
                <div className="text-center ">
                  <span >{"Arrival City Chennai"}</span>
                </div>
                <hr />
                <div className="row">

                  <div className="form-group col-lg-4 col-md-6">
                    <label className="fw-bold">
                      Travel Mode <font color="#FF0000">*</font>
                    </label>
                    <Select
                      className="my-2"
                      options={travelModerarrivalArray.map((data) => ({
                        value: data,
                        label: data,
                      }))}
                      value={
                        formData.travelMode
                          ? { value: formData.travelMode, label: formData.travelMode }
                          : null
                      }
                      required
                      onChange={(selectedOption) => { setFormData({ ...formData, travelMode: selectedOption ? selectedOption.value : null }) }}

                      isSearchable
                      placeholder="Choose One"
                    />

                  </div>

                  {formData.travelMode === "Self" ? null :

                    <>

                      {/* <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Arrival PNR <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.departurePNR}
                                required

                                placeholder="Arrival PNR"
                                name="departurePNR"
                                onChange={handleChange}
                              />
                            </div> */}
                      <div className="form-group col-lg-4 col-md-6">

                        <label className="fw-bold">
                          Departure City (Arriving From Which City) <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          value={formData.departureCity}
                          required
                          placeholder="Arrival City"
                          name="departureCity"
                          onChange={handleChange}
                        />
                      </div>

                    </>}

                  <div className="form-group col-lg-4 col-md-6">
                    <label className="fw-bold">
                      Date of Arrival <font color="#FF0000">*</font>
                    </label>
                    <DatePickers
                      selectedDate={departureDate} setSelectedDate={setDepartureDate} lable={'Date of Departure'} required={true}

                    />
                    {error_1st && <span style={{ color: 'red' }}>{dateValidation}</span>}
                  </div>

                  {formData.travelMode === "Self" ? null : <>

                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Flight/Train name <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        value={formData.flightTrainName}
                        required
                        placeholder="Flight/Train name"
                        name="flightTrainName"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Flight/Train Number <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Flight/Train Number"
                        value={formData.flightTrainNumber}
                        required
                        name="flightTrainNumber"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Arrival time at Chennai <font color="#FF0000">*</font>
                      </label>
                      <input
                        // type="text"
                        type="time"
                        className="form-control my-2"
                        placeholder="Arrival Time"
                        value={formData.arrivalTimeGoa}
                        name="arrivalTimeGoa"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Departure Time From Source <font color="#FF0000">*</font>
                      </label>
                      <input
                        // type="text"
                        type="time"
                        className="form-control my-2"
                        placeholder="Arrival Time"
                        value={formData.departure_time_from_source}
                        name="departure_time_from_source"
                        required
                        onChange={handleChange}
                      />
                    </div>

                  </>
                  }


                </div>

                <hr></hr>





              </>
              <>
                <div className="text-center">
                  <h4 className="form-heading">{"Returning Detail"}</h4>
                </div>
                <div className="text-center">
                  <span>{"Departure City Chennai"}</span>
                </div>
                <hr />

                <div className="row">

                  <div className="form-group col-lg-4 col-md-6">
                    <label className="fw-bold">
                      Travel Mode<font color="#FF0000">*</font>
                    </label>
                    <Select
                      className="my-2"
                      options={travelModerarrivalArray.map((data) => ({
                        value: data,
                        label: data,
                      }))}
                      value={
                        formData.travelReturnMode
                          ? { value: formData.travelReturnMode, label: formData.travelReturnMode }
                          : null
                      }
                      onChange={(selectedOption) => { setFormData({ ...formData, travelReturnMode: selectedOption ? selectedOption.value : null }) }}

                      required
                      isSearchable
                      placeholder="Choose One"
                    />

                  </div>




                  {formData.travelReturnMode === "Self" ? null :
                    <>
                      {/* <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Returning PNR <font color="#FF0000">*</font>
                              </label>
                              <input
                                required
                                className="form-control my-2"
                                placeholder="Returning PNR"
                                value={formData.returnPNR}

                                name="returnPNR"
                                onChange={handleChange}
                              />
                            </div> */}

                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Arrival City (Arriving In Which City) <font color="#FF0000">*</font>
                        </label>

                        <input
                          className="form-control my-2"
                          value={formData.returnCity}
                          required

                          placeholder="Returning City"
                          name="returnCity"
                          onChange={handleChange}
                        />
                      </div>
                    </>}

                  <div className="form-group col-lg-4 col-md-6">
                    <label className="fw-bold">
                      Flight/Train Date <font color="#FF0000">*</font>
                    </label>
                    <DatePickers
                      required={true}
                      selectedDate={returnFlightDate} setSelectedDate={setReturnFlightDate} lable={'Date of Departure'}

                    />
                    {error_3rd && <span style={{ color: 'red' }}>{dateValidation}</span>}

                  </div>
                  {formData.travelReturnMode === "Self" ? null : <>




                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Flight/Train Name <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        value={formData.returnFlightName}
                        required

                        placeholder="Flight/Train Name"
                        name="returnFlightName"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Flight/Train Number <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        value={formData.returnFlightNumber}
                        required

                        placeholder="Flight/Train Number"
                        name="returnFlightNumber"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Flight/Train Time <font color="#FF0000">*</font>
                      </label>
                      <input
                        // type="text"
                        type="time"
                        required

                        className="form-control my-2"
                        placeholder="Returning Time"
                        value={formData.returnFlightDepartureTime}
                        name="returnFlightDepartureTime"
                        onChange={handleChange}
                      />
                    </div>




                  </>}

                </div>

              </>
              <>
                <div className="text-center my-4">
                  <button type="submit" className="btn btn-primary mb-5" disabled={error_1st || error_3rd}>
                    {loader ? <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div> : "Submit"}
                  </button>
                </div>
              </>
            </form>
            {/* <div className="form-body-section">
              <div className="form-body">
                <div className="row">
                  <div className="form-group col-lg-6 col-md-6">
                    <label for="staticEmail" className="fw-bold">
                      Name <font color="#FF0000">*</font>
                    </label>
                    <input
                      className="form-control my-2"
                      type="text"
                      required
                      name="name"
                      value={visitorName}
                      onChange={(e) => handleContactPersonChange(e)}
                      maxLength={100}
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">
                      Mobile No. <font color="#FF0000">*</font>
                    </label>
                    <input
                      type="text"
                      className="form-control my-2"
                      required
                      name="mobile"
                      value={number}
                      maxLength={10}
                      onChange={handleMobileNoChange}
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">Email</label>
                    <input
                      type="text"
                      className="form-control my-2"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => handleEmailChange(e)}
                    />
                  </div>

                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">
                      Company Name <font color="#FF0000">*</font>
                    </label>
                    <input
                      className="form-control my-2"
                      placeholder="Company Name "
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">
                      Designation <font color="#FF0000">*</font>
                    </label>
                    <Select
                      className="my-2"
                      options={designationArray.map((data) => ({
                        value: data,
                        label: data,
                      }))}
                      value={
                        designation
                          ? { value: designation, label: designation }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setDesignation(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isSearchable
                      placeholder="Select designation"
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">You are visiting show as</label>
                    <Select
                      className="my-2"
                      options={visitingAsArray.map((data) => ({
                        value: data,
                        label: data,
                      }))}
                      value={
                        visiting_as
                          ? { value: visiting_as, label: visiting_as }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setVisiting_as(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isSearchable
                      placeholder="Select visiting as"
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">Address</label>
                    <input
                      className="form-control my-2"
                      placeholder="Address"
                      value={streetAddress}
                      onChange={(e) => {
                        setStreetAddress(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">
                      City <font color="#FF0000">*</font>
                    </label>
                    <input
                      className="form-control my-2"
                      placeholder="City"
                      value={city}
                      onChange={(e) => {
                        handleCity(e);
                      }}
                      maxLength={25}
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">State</label>
                    <input
                      className="form-control my-2"
                      placeholder="State"
                      value={state}
                      onChange={(e) => {
                        handleState(e);
                      }}
                      maxLength={25}
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">Postal Zip Code</label>
                    <input
                      className="form-control my-2"
                      placeholder="Postal Zip Code"
                      value={postalCode}
                      onChange={(e) => {
                        handlePostalCode(e);
                      }}
                      maxLength={6}
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label className="fw-bold">
                      Country <font color="#FF0000">*</font>
                    </label>
                    <input
                      className="form-control my-2"
                      placeholder="Country"
                      value={country}
                      onChange={(e) => {
                        handleCountry(e);
                      }}
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 form-group">
                    <label className="fw-bold">
                      Upload Photo <font color="#FF0000">*</font>
                      {photoStatus && (
                        <span className="photo-error">
                          Only supported (JPG/JPEG/PNG)
                        </span>
                      )}
                    </label>
                    <input
                      type="file"
                      onChange={handlePhoto}
                      className="form-control my-2"
                      accept=".jpg, .jpeg, .png"
                    />
                    <p className="photo-error">
                      Only mention format supported (JPG/JPEG/PNG)
                    </p>
                  </div>

                  <div className="col-lg-6 col-md-6 form-group">
                    <label className="fw-bold">
                      ID Proof(front) <font color="#FF0000">*</font>
                      {idFrontStatus && (
                        <span className="photo-error">
                          Only supported (JPG/JPEG/PNG)
                        </span>
                      )}
                    </label>
                    <input
                      type="file"
                      onChange={handleIDFront}
                      className="form-control my-2"
                      accept=".jpg, .jpeg, .png"
                    />
                    <p className="photo-error">
                      Only mention format supported (JPG/JPEG/PNG)
                    </p>
                  </div>

                  <div className="col-lg-6 col-md-6 form-group">
                    <label className="fw-bold">
                      ID Proof(back) <font color="#FF0000">*</font>
                      {idBackStatus && (
                        <span className="photo-error">
                          Only supported (JPG/JPEG/PNG)
                        </span>
                      )}
                    </label>
                    <input
                      type="file"
                      onChange={handleIDBack}
                      className="form-control my-2"
                      accept=".jpg, .jpeg, .png"
                    />
              
                    <p className="photo-error">
                      Only mention format supported (JPG/JPEG/PNG)
                    </p>
                  </div>

                  <div className="form-submit">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={handleSubmit}
                    >
                      {isLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div> */}

          </div>
        </>
      )}
      {remarks && (
        <>
          {/*         
          <Modal
            show={remarks}
            onHide={() => setRemarks(false)}
            animation={false}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body>{remarksValue}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setRemarks(false)}>
                Close
              </Button>
             
            </Modal.Footer>
          </Modal> */}

          <Modal
            show={showAllDetails}
            onHide={() => setRemarks(false)}
            animation={false}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                style={{
                  width: "100%",
                  minHeight: "200px",
                  resize: "none",
                  padding: "10px",
                  border: "solid 1px #ccc",
                  borderRadius: "10px",
                }}
                onChange={(e) => setRemarksValue(e.target.value)}
                value={remarksValue}
              ></textarea>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setRemarks(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleRemarks();
                }}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      {isHovered && (
        <div className="">
          <Modal
            size="xl"
            show={isModalVisible}
            onHide={() => setIsModalVisible(false)}
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-auto">
              {" "}
              <img alt="" src={imageValue} className="modal-img" />
            </Modal.Body>
          </Modal>
        </div>
      )}
      <SubAdminNavbar />
      <div className="container">
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          animation={false}
          backdrop="static"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              <div className="fs-2 ">
                Hotel, Flight and Cab details of
                <span className="fw-bold">&nbsp;{modalName}</span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span className="fw-bold fs-3">Flight Detail</span>
              <Table responsive className="table table-striped p-2 data-table">
                <thead>
                  <tr>
                    <th scope="col">Arrival Flight No.</th>
                    <th scope="col">Arrival Flight Date</th>
                    <th scope="col">Arrival Flight Time</th>
                    <th scope="col">Departure Flight No.</th>
                    <th scope="col">Departure Flight Date</th>
                    <th scope="col">Departure Flight Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {flight_details.arrival_flight_no
                        ? flight_details.arrival_flight_no
                        : "_"}
                    </td>
                    <td>
                      {flight_details.arrival_date
                        ? flight_details.arrival_date
                        : "-"}
                    </td>
                    <td>
                      {flight_details.arrival_time
                        ? flight_details.arrival_time
                        : "-"}
                    </td>

                    <td>
                      {flight_details.departure_flight_no
                        ? flight_details.departure_flight_no
                        : "-"}
                    </td>
                    <td>
                      {flight_details.departure_date
                        ? flight_details.departure_date
                        : "-"}
                    </td>
                    <td>
                      {flight_details.departure_time
                        ? flight_details.departure_time
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <hr />
            <div>
              <span className="fw-bold fs-3">Hotel Detail</span>
              <Table responsive className="table table-striped p-2 data-table">
                <thead>
                  <tr>
                    <th scope="col">Hotel Name:</th>
                    <th scope="col">Date for Booking:</th>
                    <th scope="col">Room No:</th>
                    {/* <th scope="col">Room Allotation Time: </th>
                    <th scope="col">Hotel Allotation Time: </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {hotelDetail.hotel_name ? hotelDetail.hotel_name : "-"}
                    </td>
                    <td>
                      {hotelDetail.booking_date
                        ? hotelDetail.booking_date
                        : "-"}
                    </td>
                    <td>{hotelDetail.room_no ? hotelDetail.room_no : "-"}</td>
                    {/* <td>
                      {hotelDetail.room_allotation_time
                        ? hotelDetail.room_allotation_time
                        : "-"}
                    </td>
                    <td>
                      {hotelDetail.hotel_allotation_time
                        ? hotelDetail.hotel_allotation_time
                        : "-"}
                    </td> */}
                  </tr>
                </tbody>
              </Table>
            </div>
            <hr />
            <div>
              <span className="fw-bold fs-3">Cab Detail</span>
              <Table
                responsive
                className="table table-striped p-2 data-table m-0"
              >
                <thead>
                  <tr>
                    <th scope="col-3" className="w-25">
                      Sr. No.
                    </th>
                    <th scope="col-3" className="w-25">
                      Route
                    </th>
                    <th scope="col-3" className="w-25">
                      cab Number
                    </th>
                    <th scope="col-3" className="w-25">
                      Booking time{" "}
                    </th>
                  </tr>
                </thead>
              </Table>
              <div
                className="nodesure"
                style={{ maxHeight: "150px", overflowY: "scroll" }}
              >
                <Table
                  responsive
                  className="table table-striped p-3 data-table"
                >
                  {/* <thead>
                    <tr>
                    <th scope="col-3" className="w-25">Sr. No.</th>
                      <th scope="col-3" className="w-25">Route</th>
                      <th scope="col-3" className="w-25">cab Number</th>
                      <th scope="col-3" className="w-25">Booking time </th>
                    </tr>
                  </thead> */}
                  <tbody>
                    {cab_detail.map((data, idx) => {
                      return (
                        <tr key={idx}>
                          <th scope="col-3" className="w-25">
                            {idx + 1}
                          </th>
                          <td className="w-25">{data.route}</td>
                          <td className="w-25">{data.cab_number}</td>
                          <td className="w-25">
                            {moment(data.booking_date).format("lll")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
      {!editArea && (
        <div className="">
          <div className="text-center">
            <h3 className="form-heading">Invited Buyer List</h3>
          </div>

          {loadingState ? (
            <>
              <div className="d-flex justify-content-between mx-5 my-2">
                <button
                  className="btn btn-outline-success ms-3"
                  type="submit"
                  onClick={() => downloadAdminVipRequestList()}
                >
                  Download Excel
                </button>
                <div>
                  <Select
                    placeholder="Select Ticket Booked"
                    className="my-2"
                    options={[
                      { value: 'exhibitor_owner', label: 'Exhibitor' },
                      { value: 'exhibitor_vip', label: 'Buyer' }

                    ]}
                    value={

                      selected_category
                    }
                    onChange={(option) => {
                      // handleSelect(option);
                      setSelected_category(option)

                      // setListQuery()
                    }}
                    isSearchable
                  />
                </div>
                <form className="d-flex" role="search">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </form>
              </div>{" "}
              {vipGuestList.length > 0 ? (
                <>
                  {" "}
                  <Table
                    responsive
                    className="table table-striped my-3 p-3 data-table"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Reg. No.</th>
                        <th scope="col">Visitor Name</th>
                        <th scope="col">Company Name </th>
                        <th scope="col">Mobile No.</th>
                        {/* <th scope="col">Gender</th> */}


                        {/* <th scope="col">Day</th> */}
                        {/* <th scope="col">City</th>
                        <th scope="col">Invited By</th> */}
                        <th scope="col">Reg. Date </th>
                        <th scope="col">Image</th>
                        <th scope="col">ID Card</th>
                        {/* <th scope="col">Arrival Time</th>
                        <th scope="col">Departure Time</th>
                        <th scope="col">Preference</th> */}
                        {/* <th scope="col">Trip Type</th> */}
                        <th scope="col">Mode</th>
                        <th scope="col">Return Travel Mode</th>

                        {/* <th scope="col">Download</th> */}
                        <th scope="col">Travel Preference</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vipGuestList?.map((data, idx) => {
                        return (
                          <tr key={idx}>
                            {/* <th scope="row">{data?.new_sort_number}</th> */}
                            <th scope="row"> {(dummyPage - 1) * limit + idx + 1}</th>

                            <td>{data.barcode}</td>
                            <td>{`${data?.title ? data?.title : ''} ${data.visitor_name.toUpperCase()}`}</td>
                            <td>{data.company_name ? data.company_name : data?.companyData[0]?.company_name}</td>
                            <td>{data.mobile_number}</td>
                            {/* <td>{data?.gender}</td> */}

                            {/* <td>{data.visiting_day}</td> */}
                            {/* <td>{data.city}</td> */}
                            {/* <td>
                              {" "}
                              {data.companyData[0]
                                ? data.companyData[0].company_name
                                : "__"}
                            </td> */}
                            <td>
                              {moment(data.createdAt).format("MMM Do YY")}
                            </td>

                            <td>
                              {data.photo ? (
                                <>
                                  <div
                                    className={`img-size ${isHovered ? "image-hovered" : ""
                                      }`}
                                  >
                                    <img
                                      className="img-size"
                                      loading="lazy"
                                      src={`${process.env.REACT_APP_IMAGE_URL
                                        }/photo/${data.photo
                                        }?v=${moment().unix()}`}
                                      alt=""
                                      onerror="this.src='-'"
                                      onClick={() => {
                                        setImageValue(
                                          `${process.env.REACT_APP_IMAGE_URL
                                          }/photo/${data.photo
                                          }?v=${moment().unix()}`
                                        );
                                        setIsHovered(true);
                                        setIsModalVisible(true);
                                        setModalHeader(
                                          `${data.visitor_name} Photo`
                                        );
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td>
                              <div className="d-flex">
                                {data.id_proof_front ? (
                                  <div className="front-id-proof">
                                    <div className="main-img">
                                      <img
                                        // className="img-size"
                                        loading="lazy"
                                        src={`${process.env.REACT_APP_IMAGE_URL
                                          }/id_proof/${data.id_proof_front
                                          }?v=${moment().unix()}`}
                                        alt=""
                                        onerror="this.src='-'"
                                        className={`img-size ${isHovered ? "image-zoom" : ""
                                          }`}
                                        onClick={() => {
                                          setImageValue(
                                            `${process.env.REACT_APP_IMAGE_URL
                                            }/id_proof/${data.id_proof_front
                                            }?v=${moment().unix()}`
                                          );
                                          setIsHovered(true);
                                          setIsModalVisible(true);
                                          setModalHeader(
                                            `${data.visitor_name} ID Proof Front`
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <span>-</span>
                                )}
                                {data.id_proof_back ? (
                                  <div>
                                    <img
                                      // className="img-size"
                                      loading="lazy"
                                      src={`${process.env.REACT_APP_IMAGE_URL
                                        }/id_proof/${data.id_proof_back
                                        }?v=${moment().unix()}`}
                                      alt=""
                                      onerror="this.src='-'"
                                      className={`img-size ${isHovered ? "image-zoom" : ""
                                        }`}
                                      onClick={() => {
                                        setImageValue(
                                          `${process.env.REACT_APP_IMAGE_URL
                                          }/id_proof/${data.id_proof_back
                                          }?v=${moment().unix()}`
                                        );
                                        setIsHovered(true);
                                        setIsModalVisible(true);
                                        setModalHeader(
                                          `${data.visitor_name} ID Proof Back`
                                        );
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <span>-</span>
                                )}
                              </div>
                            </td>
                            {/* <td>
                      <div className="pointer-cursor">
                        <MdOutlineFlight /> <AiOutlineFileImage />
                      </div>
                    </td> */}
                            {/* <td>
                              <Button
                                onClick={() => {
                                  setModalName(data.visitor_name);
                                  setShowModal(true);
                                  setBooking_date(data.booking_date);
                                  setRoom_No(data.room_no);
                                  setRoom_allocation_time(
                                    data.room_allotation_time
                                  );
                                  setHotel_allocation_time(
                                    data.hotel_allotation_time
                                  );
                                  setHotel_name(data.hotel_name);
                                  setcab_detail(data.cab_detail);
                                  sethotelDetail(data.hotel_detail);
                                  setflight_details(
                                    data.flight_details
                                      ? data.flight_details
                                      : "-"
                                  );
                                }}
                              >
                                Info
                              </Button>
                            </td> */}
                            {/* <td>
                              {formatDateTime(
                                data?.travel_detail?.arrival_date_time
                              )}
                            </td>
                            <td>
                              {formatDateTime(
                                data?.travel_detail?.departure_date_time
                              )}
                            </td>
                            <td>
                              {data?.travel_detail?.preffered_train_flight}
                            </td> */}
                            <td>
                              {data?.travelMode
                                ? data?.travelMode
                                : "_"}
                            </td>
                            <td>
                              {data?.travelReturnMode
                                ? data?.travelReturnMode
                                : "_"}
                            </td>
                            <td><BsFillEyeFill style={{ cursor: 'pointer' }} onClick={() => {
                              setShowAllDetails(true);
                              setDataShowDetail(data)
                            }} /></td>
                            <td>
                              <Button
                                onClick={() => {
                                  setRemarks(true);
                                  setRemarksValue(
                                    data.remarks_value ? data.remarks_value : ""
                                  );

                                  setEditID(data._id);
                                }}
                              >
                                Remarks
                              </Button>
                            </td>
                            <td>
                              <BiEdit
                                size={20}
                                title="Edit Buyer"
                                onClick={() => handleEdit(data)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {!editArea && (
                    <div className="d-flex mx-2 justify-content-end">
                      <Pagination
                        dummyPage={setDummyPage}
                        totalLength={totalLength}
                        limit={limit}
                        pageNo={page}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <div className="no-data-div content-height">
                    <h5 className="no-data-heading">No Record Found</h5>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {" "}
              <div className="content-height d-flex justify-content-center align-items-center">
                <Spinner animation="border" size="lg" />
              </div>
            </>
          )}
        </div>
      )}
      <Footer />
    </>
  );
};

export default AdminInvitedVIPGuestList;
