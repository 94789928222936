import React from "react";
import MainLogo from "../../assets/image/logo-image.png";

const FrontPage = () => {
  return (
    <>
      <div className="text-center">
        <h1 className="display-1">Welcome</h1>
        <h1 className="display-4">to</h1>
        <img src={MainLogo} className="img-fluid" alt="Main Logo" style={{ width: '400px', height: 'auto' }} />
      </div>
      <div>
        <h1 style={{ fontSize: '10rem', fontWeight: 'bold', textAlign: 'center', }}>{process.env.REACT_APP_ORGANIZER_NAME}</h1>
      </div>
    </>
  );
};

export default FrontPage;
