import React from 'react';
import logo from '../../assets/image/logo-image.png'

const ThankYou = () => {
    return (
        <div>
            <div className='d-flex justify-content-center align-items-center my-5'>
                <img src={logo} style={{ width: '200px', heigh: 'auto' }} />
            </div>
            <div className='d-flex justify-content-center align-items-center my-5'>
                <h1 className='my-3'>Thank you for your submission!</h1>
            </div>
            <div className='d-flex justify-content-center align-items-center my-5'>
                <p>Your form has been submitted successfully.</p>
            </div>

        </div>
    );
}

export default ThankYou;
